import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Typography,
  message,
} from 'antd'
import React from 'react'

import logo2 from '../assets/INFINITFY - LOGO - PRETO.png'

import { Navigate } from 'react-router-dom'
import { POST_API, POST_CATCH, setToken } from '../services/api'
import ParticlesLogin from './ParticlesLogin'

class Login extends React.Component {
  state = { navigate: false }

  onSend = (values) => {
    values.TYPE = 'P,C,A'

    POST_API('/credential/login.php', {
      token: 'X',
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success(res.msg)
          setToken(res.token)
          this.setState({ navigate: true })
        } else {
          message.warning(res.msg)
        }
      })
      .catch(() => {
        POST_CATCH()
      })
      .finally(() => {
        this.setState({ load: false })
      })
  }

  render = () => {
    if (this.state.navigate) return <Navigate to="/admin" />

    return (
      <Layout.Content className="login">
        <Row>
          <ParticlesLogin />
          <Col span={24} style={{ marginTop: -100 }}>
            <Card>
              <Row gutter={[8, 8]} align={'middle'}>
                <Col md={10} xs={24}>
                  <center>
                    <Image preview={false} className="login-img" src={logo2} />
                  </center>
                </Col>
                <Col md={2} xs={0}>
                  <center>
                    <Divider type="vertical" style={{ height: 200 }} />
                  </center>
                </Col>
                <Col md={0} xs={24}>
                  <center>
                    <Divider type="horizontal" />
                  </center>
                </Col>
                <Col md={12} xs={24}>
                  <Typography className="login-text">Fazer login</Typography>
                  <Form
                    layout="vertical"
                    style={{ marginTop: 20 }}
                    onFinish={this.onSend}
                  >
                    <Form.Item
                      name="LOGIN"
                      label="E-mail"
                      rules={[
                        { required: true, message: 'Preenha seu e-mail' },
                        { type: 'email', message: 'E-mail inválido' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="PASS"
                      label="Senha"
                      rules={[{ required: true, message: 'Digite sua senha' }]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        block
                        size="large"
                        shape="round"
                        type="primary"
                        className="btn-primary"
                        htmlType="submit"
                      >
                        Entrar
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    )
  }
}

export default Login
