import {
  Button,
  Carousel,
  Col,
  Divider,
  FloatButton,
  Image,
  Layout,
  Popover,
  Row,
  Typography,
  message,
} from 'antd'
import React from 'react'

import img1 from '../assets/figma/1.png'
import img2 from '../assets/figma/2.png'
import img3 from '../assets/figma/3.png'
import cel from '../assets/figma/cel.png'

import { IoLogoWhatsapp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import ParticlesDark from './ParticlesDark'
import ParticlesLight from './ParticlesLight'
import { Fade } from 'react-reveal'
import CountUp from 'react-countup'
import CardNoticia from '../components/CardNoticia'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

class Main extends React.Component {
  state = {
    user: null,
    navigate: false,
    screen: getScreen(),
    select: '',
    loading: false,
    parceiros: [],
    blog: [],
    title: 'Inovando negócios com SAP BTP',
    desc: 'Bem-vindo à Infinitfy, onde a inovação não é apenas uma palavra; é nossa linguagem. Somos pioneiros em soluções inovadoras para o ecossistema SAP. Destacamo-nos como uma das primeiras empresas brasileiras a ter produtos certificados e publicados na SAP Store, sendo reconhecidos como parceiros SAP Build Edge na categoria Build.',
  }

  loadParceiros = () => {
    POST_API('/client/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 999 }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ parceiros: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  loadBlog = () => {
    POST_API('/blog/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 3 }),
      sorter: JSON.stringify({
        columnKey: 'DATETIME_INSERT',
        order: 'descend',
      }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ blog: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  componentDidMount = () => {
    const params = window.location.href
    const values = params.split('/')

    window.scrollTo(0, 0)

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    POST_API('/config/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 999 }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ title: res.data.TITLE, desc: res.data.DESC })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    this.loadParceiros()
    this.loadBlog()
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value })
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          {this.state.screen === true || this.state.screen === 'true' ? (
            <ParticlesDark title={this.state.title} desc={this.state.desc} />
          ) : (
            <ParticlesLight title={this.state.title} desc={this.state.desc} />
          )}
          <Col
            md={24}
            xs={0}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Métricas
                </Typography>
              </center>
              <div className="metricas">
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                >
                  <Col>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={60}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Projetos
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Projetos concluídos
                    </Typography>
                  </Col>
                  <Col>
                    <Divider type="vertical" className="divider-metrica" />
                  </Col>
                  <Col>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={10}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Clientes
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Empresas atendidas
                    </Typography>
                  </Col>
                  <Col>
                    <Divider type="vertical" className="divider-metrica" />
                  </Col>
                  <Col>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={25}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>
                      k Horas
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Horas de projeto
                    </Typography>
                  </Col>
                  <Col>
                    <Divider type="vertical" className="divider-metrica" />
                  </Col>
                  <Col>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={10}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Anos
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Experiência no mercado
                    </Typography>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col
            xs={24}
            md={0}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Métricas
                </Typography>
              </center>
              <div className="metricas">
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{ paddingLeft: 50, paddingRight: 50 }}
                >
                  <Col span={24}>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={60}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Projetos
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Projetos concluídos
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Divider type="horizontal" className="divider-metrica" />
                  </Col>
                  <Col span={24}>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={10}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Clientes
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Empresas atendidas
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Divider type="horizontal" className="divider-metrica" />
                  </Col>
                  <Col span={24}>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={25}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>
                      k Horas
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Horas de projeto
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Divider type="horizontal" className="divider-metrica" />
                  </Col>
                  <Col span={24}>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-large'
                          : 'metrica-light metrica-large'
                      }
                    >
                      +{' '}
                      <CountUp
                        start={0}
                        end={10}
                        delay={0}
                        duration={3}
                        enableScrollSpy
                      >
                        {' '}
                        {({ countUpRef, start }) => ({ countUpRef })}{' '}
                      </CountUp>{' '}
                      Anos
                    </Typography>
                    <Typography
                      className={
                        this.state.screen === true ||
                        this.state.screen === 'true'
                          ? 'metrica-dark metrica-small'
                          : 'metrica-light metrica-small'
                      }
                    >
                      Experiência no mercado
                    </Typography>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col
            md={24}
            xs={0}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Nossos Serviços
                </Typography>
              </center>
              <div className="metricas">
                <Row justify={'space-between'} align={'middle'}>
                  <Col>
                    <Link to="servicos">
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img1} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Fábrica de Software
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="servicos">
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img2} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Fábrica de Inovação
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="servicos">
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img3} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Alocação de Recursos
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col
            md={0}
            xs={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Nossos Serviços
                </Typography>
              </center>
              <div className="metricas">
                <Row justify={'space-between'} align={'middle'}>
                  <Col span={24}>
                    <center>
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img1} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Fábrica de Software
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center>
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img2} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Fábrica de Inovação
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center>
                      <div
                        className={
                          'services-card ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'services-card-dark'
                            : 'services-card-light')
                        }
                      >
                        <div className="services-card-icon">
                          <Image width={'100%'} preview={false} src={img3} />
                        </div>
                        <div>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-large'
                                : 'services-light services-large'
                            }
                          >
                            Alocação de Recursos
                          </Typography>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'services-dark services-small'
                                : 'services-light services-small'
                            }
                          >
                            Saiba mais
                          </Typography>
                        </div>
                      </div>
                    </center>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col
            span={24}
            className={
              'main-section chatsync ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Outros Produtos
                </Typography>
              </center>
              <Row style={{ marginTop: 80 }}>
                <Col md={10} xs={24}>
                  <Image preview={false} src={cel} />
                </Col>
                <Col md={14} xs={24} style={{ marginTop: 25 }}>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-title-dark'
                        : 'main-title-light'
                    }
                  >
                    ChatSync - O Assistente Virtual SAP para Transformar seu
                    Negócio
                  </Typography>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-text-dark'
                        : 'main-text-light'
                    }
                    style={{ marginBottom: 10, marginTop: 10 }}
                  >
                    O ChatSync é a revolução na integração de comunicação.
                    Conecte-se pelo WhatsApp e Teams com o SAP ECC/S4Hana. Com
                    Chatbots e Inteligência Artificial baseados no SAP BTP, o
                    ChatSync possibilita:
                  </Typography>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-text-dark'
                        : 'main-text-light'
                    }
                  >
                    1. Agilizar aprovações e rejeições de pedidos de compra.
                  </Typography>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-text-dark'
                        : 'main-text-light'
                    }
                  >
                    2. Distribuir relatórios financeiros para diretores e
                    acionistas.
                  </Typography>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-text-dark'
                        : 'main-text-light'
                    }
                  >
                    3. Facilitar o reset de usuários e senhas nos sistemas SAP,
                    via WhatsApp.
                  </Typography>
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'main-text-dark'
                        : 'main-text-light'
                    }
                  >
                    4. E muito mais.....
                  </Typography>
                  <center>
                    <Link
                      to="https://store.sap.com/dcp/en/product/display-2001016388_live_v1"
                      target="_blank"
                    >
                      <Button
                        style={{ marginTop: 15 }}
                        className={
                          'btn-primary ' +
                          (this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'button-contat-dark'
                            : 'button-contat-light')
                        }
                        type="primary"
                      >
                        Saiba mais
                      </Button>
                    </Link>
                  </center>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Clientes
                </Typography>
              </center>
              <Typography
                className={
                  this.state.screen === true || this.state.screen === 'true'
                    ? 'client-title-dark'
                    : 'client-title-light'
                }
              >
                Nossa Consultoria é a Chave para Impulsionar o Crescimento da
                sua Empresa:
              </Typography>
              <Typography
                className={
                  this.state.screen === true || this.state.screen === 'true'
                    ? 'client-text-dark'
                    : 'client-text-light'
                }
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Estamos empenhados em otimizar o uso da tecnologia, aprimorar
                operações e aumentar a eficiência para impulsionar a
                lucratividade. Identificamos falhas, implementamos soluções e
                gerenciamos tecnologias essenciais para atingir seus objetivos
                comerciais. Conte conosco para oferecer expertise em estratégia
                de tecnologia, desenvolvimento de software, integração de
                sistemas, segurança cibernética, análise de dados e muito mais.
                Juntos, construímos um caminho sólido para o sucesso da sua
                empresa.
              </Typography>
              <Row style={{ marginTop: 100 }}>
                <Col md={24} xs={0}>
                  <Carousel autoplay slidesToShow={4}>
                    {this.state.parceiros.map((v, i) => (
                      <div key={i}>
                        <center>
                          <Image
                            style={{
                              width: 150,
                              height: 150,
                              objectFit: 'contain',
                              marginBottom: 40,
                            }}
                            src={v.AVATAR}
                            preview={false}
                          />
                        </center>
                      </div>
                    ))}
                  </Carousel>
                </Col>
                <Col md={0} xs={24}>
                  <Carousel autoplay slidesToShow={2}>
                    {this.state.parceiros.map((v, i) => (
                      <div key={i}>
                        <center>
                          <Image
                            style={{
                              width: 150,
                              height: 150,
                              objectFit: 'contain',
                              marginBottom: 40,
                            }}
                            src={v.AVATAR}
                            preview={false}
                          />
                        </center>
                      </div>
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
          >
            <Fade>
              <center>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'header-title-dark'
                      : 'header-title-light'
                  }
                >
                  Conheça nosso Blog!
                </Typography>
              </center>
              <Row style={{ marginTop: 80 }} gutter={[16, 16]}>
                {this.state.blog.map((v, i) => (
                  <Col key={v} xs={24} md={8} style={{ marginBottom: 15 }}>
                    <Link to={'blog/' + v.ID}>
                      <CardNoticia item={v} screen={this.state.screen} />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Fade>
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default Main
