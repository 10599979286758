import { Button, Col, Image, Layout, Row, Typography, message } from 'antd'
import React from 'react'

import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import { Fade } from 'react-reveal'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

class ChatSync extends React.Component {
  state = {
    screen: getScreen(),
    value: getScreen() === true || getScreen() === 'true' ? 'dark' : 'light',
    select: '',
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value, value: value ? 'dark' : 'light' })
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            <Row gutter={[32, 64]} style={{ marginTop: 20 }} justify={'center'}>
              <Col md={14} xs={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    <span>ChatSync:</span>
                  </Typography>
                  <Typography className={`who-title-${this.state.value}`}>
                    Acelere sua inovação, integre soluções e automatize
                    processos no seu ambiente SAP
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    <b>Tudo na palma da mão:</b> aprovação de pedido de compra,
                    relatórios financeiros, reset de usuários de sistemas,
                    aprovação de documento financeiro, entre outras finalidades.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706715506/$wvxslbtxfp"
                    preview={false}
                  />
                </Fade>
              </Col>
              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706715506/$vueaotg3c58"
                    preview={false}
                  />
                </Fade>
              </Col>
              <Col md={14} xs={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    <span>30% de redução</span> nos chamados abertos para a
                    equipe de suporte
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    O ChatSync é uma solução inovadora para automatizar e
                    otimizar as operações da sua empresa.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Sua plataforma é fácil de usar e não requer treinamento
                    extensivo. Ela se integra a diversos canais de comunicação,
                    incluindo WhatsApp, Telegram, Slack, Messenger, Instagram,
                    Discord, entre outros, facilitando a comunicação e o fluxo
                    de informações da sua equipe.
                  </Typography>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      <span>Aumente a produtividade</span> ao melhorar a
                      comunicação entre as equipes internas
                    </Typography>
                  </center>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Facilidade de Uso
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Usuário-amigável e não requer treinamento extensivo.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Integração com canais de comunicação
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Compatível com WhatsApp, Telegram, Microsoft Teams, Discord,
                    Slack, Skype e mais.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Recebimento de notificações
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Receba notificações importantes diretamente no celular,
                    refletindo ações em seu ERP.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Conexão segura
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Múltiplas validações, incluindo autenticação de dois
                    fatores, para garantir a integridade dos dados.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Automatização de processos
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Automatize desde a aprovação de pedidos de compra até
                    relatórios financeiros para executivos.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Integração com SAP
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Receba notificações instantâneas para ações importantes,
                    acelerando a tomada de decisões.
                  </Typography>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <center>
                    <Link to="https://store.sap.com/dcp/en/product/display-2001016388_live_v1/chatsync">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-destaque`}
                        type="primary"
                      >
                        Confira na SAP Store
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Eles já <span>estão usando</span>
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24}>
                <Fade>
                  <Image
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706291541/$p6dytrlab1"
                    preview={false}
                  />
                </Fade>
              </Col>
              <Col md={18} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    No Burger King, o ChatSync ajudou a reduzir a taxa de
                    abertura de tickets em 30%. Isso foi possível porque a
                    ferramenta facilitou a recuperação de senhas pelo celular.
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24}>
                <Fade>
                  <Image
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706291541/$9m3vlbmxmwq"
                    preview={false}
                  />
                </Fade>
              </Col>
              <Col md={18} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    Já a Atem & Ream utilizou o ChatSync para facilitar a
                    aprovação de ordens de compra. Com a ferramenta, os
                    funcionários podem aprovar ordens de compra diretamente pelo
                    celular, sem precisar acessar o ERP. Isso agilizou o
                    processo de aprovação e ajudou a reduzir o tempo de ciclo
                    das ordens.
                  </Typography>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <center>
                    <Link to="https://store.sap.com/dcp/en/product/display-2001016388_live_v1/chatsync">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-destaque`}
                        type="primary"
                      >
                        Confira na SAP Store
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      Conheça outras soluções
                    </Typography>
                  </center>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      Infinitfy para o seu negócio
                    </Typography>
                  </center>
                  <center>
                    <Typography className={`who-text-${this.state.value}`}>
                      A Infinitfy promove a transformação digital de empresas
                      usuárias de ERP SAP
                    </Typography>
                  </center>
                  <center>
                    <Link to="/servicos">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-destaque`}
                        type="primary"
                      >
                        Soluções Infinity
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      <span>Infinitfy</span> Where innovation knows no bounds
                    </Typography>
                  </center>
                  <center>
                    <Typography className={`who-text-${this.state.value}`}>
                      Para nós, a inovação é mais do que uma palavra –{' '}
                      <b>é uma jornada.</b>
                    </Typography>
                  </center>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Infinitfy
                  </Typography>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Transformando grandes ideias em soluções de negócios
                  </Typography>
                </Fade>
              </Col>
            </Row>
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default ChatSync
