import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Layout,
  List,
  Pagination,
  Result,
  Row,
  Spin,
  Switch,
  Typography,
  message,
} from 'antd'
import React from 'react'

import logo1 from '../assets/INFINITFY - LOGO - BRANCO.png'
import logo2 from '../assets/INFINITFY - LOGO - PRETO.png'

import fav from '../assets/infinitfy.logo-1-300x300.png'
import sap from '../assets/sap.png'

import parse from 'html-react-parser'

import { FiMapPin } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import {
  IoLogoInstagram,
  IoLogoYoutube,
  IoMenuOutline,
  IoMoonOutline,
  IoSunnyOutline,
  IoLogoLinkedin,
  IoGlobeOutline,
  IoSendOutline,
  IoMailOutline,
  IoCalendarOutline,
  IoTimeOutline,
  IoChatbubbleEllipsesOutline,
  IoPricetagOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import ParticlesDark from './ParticlesDark'
import ParticlesLight from './ParticlesLight'
import { Fade } from 'react-reveal'
import CountUp from 'react-countup'
import CardNoticia from '../components/CardNoticia'
import YouTube from 'react-youtube'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

class BlogPage extends React.Component {
  state = {
    _screen: true,
    loading: true,
    blognew: [],
    blogcomment: [],
    _params: window.location.href.split('/'),
    rows: [],
    defaultValue: null,
    _loading: false,
    screen: getScreen(),
    select: '',
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    this.onView()
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value })
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  onSend = (values) => {
    this.setState({ _loading: true })
    values.BLOG_ID = window.location.href.split('/')[4]
    POST_API('/blog_comment/save.php', {
      token: 'X',
      type: 'add',
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
          this.loadBlogComment()
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _loading: false }))
  }

  loadBlogNew = () => {
    POST_API('/blog/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 2 }),
      sorter: JSON.stringify({
        columnKey: 'DATETIME_INSERT',
        order: 'descend',
      }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ blognew: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  loadBlogComment = () => {
    POST_API('/blog_comment/search.php', {
      token: 'X',
      filter: JSON.stringify({ BLOG_ID: window.location.href.split('/')[4] }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ blogcomment: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  onView = async () => {
    setTimeout(() => {
      this.loadBlogComment()
      this.loadBlogNew()
      this.setState({ _screen: true })
      window.scrollTo(0, 0)
      POST_API('/access_blog/save.php', {
        token: 'X',
        type: 'list',
        master: JSON.stringify({ GUID: window.location.href.split('/')[4] }),
      })
        .then((rs) => rs.json())
        .then((res) => {
          if (!res.return) {
            message.warning({ content: res.msg, key: 'screen' })
          }
        })
        .catch(() => POST_CATCH())
      POST_API('/blog_page/search.php', {
        token: 'X',
        type: 'list',
        filter: JSON.stringify({ GUID: window.location.href.split('/')[4] }),
        sorter: JSON.stringify({
          columnKey: 'blog_page.`ROW`, blog_page.`COLUMN`, blog_page.`ITEM`',
          order: 'ascend',
        }),
      })
        .then((rs) => rs.json())
        .then((res) => {
          if (res.return) {
            this.setState({ rows: res.data })
            console.log(res.data)
          } else {
            message.warning({ content: res.msg, key: 'screen' })
          }
        })
        .catch(() => POST_CATCH())
      POST_API('/blog/search.php', {
        token: 'X',
        type: 'view',
        filter: JSON.stringify({ GUID: window.location.href.split('/')[4] }),
      })
        .then((rs) => rs.json())
        .then((res) => {
          if (res.return) {
            this.setState({ defaultValue: res.data })
          } else {
            message.warning({ content: res.msg, key: 'screen' })
          }
        })
        .catch(() => POST_CATCH())
        .finally(() => this.setState({ _screen: false }))
    }, 500)
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            {this.state._screen ? (
              <center>
                <Spin />
              </center>
            ) : (
              <>
                {this.state.defaultValue?.ID > 0 ? (
                  <Row gutter={[16, 16]}>
                    <Col md={16} xs={24}>
                      <Fade clear>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'blog-page-title-dark'
                              : 'blog-page-title-light'
                          }
                        >
                          {this.state.defaultValue?.TITLE}
                        </Typography>
                      </Fade>
                      <Fade>
                        <Row>
                          <Col
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'blog-page-detail-dark'
                                : 'blog-page-detail-light'
                            }
                          >
                            <IoCalendarOutline
                              size={16}
                              style={{ marginRight: 5 }}
                            />
                            {this.state.defaultValue?.BLOG_DATE}
                          </Col>
                          <Col>
                            <Divider type="vertical" />
                          </Col>
                          <Col
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'blog-page-detail-dark'
                                : 'blog-page-detail-light'
                            }
                          >
                            <IoTimeOutline
                              size={16}
                              style={{ marginRight: 5 }}
                            />
                            {this.state.defaultValue?.BLOG_TIME}
                          </Col>
                          <Col>
                            <Divider type="vertical" />
                          </Col>
                          <Col
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'blog-page-detail-dark'
                                : 'blog-page-detail-light'
                            }
                          >
                            <IoChatbubbleEllipsesOutline
                              size={16}
                              style={{ marginRight: 5 }}
                            />
                            {this.state.defaultValue?.COMMENT === '0'
                              ? 'Sem comentários'
                              : this.state.defaultValue?.COMMENT === '1'
                                ? '1 comentário'
                                : this.state.defaultValue?.COMMENT +
                                  ' comentários'}
                          </Col>
                          <Col>
                            <Divider type="vertical" />
                          </Col>
                          <Col
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'blog-page-detail-dark'
                                : 'blog-page-detail-light'
                            }
                          >
                            <IoPricetagOutline
                              size={16}
                              style={{ marginRight: 5 }}
                            />
                            {this.state.defaultValue?.BLOG_CATEGORY_NAME}
                          </Col>
                          <Col>
                            <Divider type="vertical" />
                          </Col>
                          <Col
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'blog-page-detail-dark'
                                : 'blog-page-detail-light'
                            }
                          >
                            <Avatar
                              src={this.state.defaultValue?.CREDENTIAL_AVATAR}
                              size={22}
                              style={{ marginRight: 5 }}
                            />
                            {this.state.defaultValue?.CREDENTIAL_NAME}
                          </Col>
                        </Row>
                      </Fade>
                      <Fade>
                        <center>
                          <Image
                            className="blog-page-image"
                            src={this.state.defaultValue?.AVATAR}
                            preview={false}
                          />
                        </center>
                      </Fade>
                      {this.state.rows.map((v, i) => (
                        <Row key={i} className="blog-page-row">
                          {v.columns &&
                            v.columns.map((cv, ci) => (
                              <Col
                                key={ci}
                                md={24 / v.type}
                                xs={24}
                                className="blog-page-column"
                              >
                                <Row>
                                  {cv.items.map((iv, ii) => (
                                    <Col
                                      span={24}
                                      key={ii}
                                      style={{ marginBottom: 10 }}
                                    >
                                      {iv.type === 'T' ? (
                                        <Fade>
                                          <Typography
                                            className={
                                              this.state.screen === true ||
                                              this.state.screen === 'true'
                                                ? 'blog-page-subtitle-dark'
                                                : 'blog-page-subtitle-light'
                                            }
                                          >
                                            {iv.value}
                                          </Typography>
                                        </Fade>
                                      ) : null}
                                      {iv.type === 'P' ? (
                                        <Fade>
                                          <Typography
                                            className={
                                              this.state.screen === true ||
                                              this.state.screen === 'true'
                                                ? 'blog-page-paragraph-dark'
                                                : 'blog-page-paragraph-light'
                                            }
                                          >
                                            {parse(iv.value)}
                                          </Typography>
                                        </Fade>
                                      ) : null}
                                      {iv.type === 'I' ? (
                                        <Fade>
                                          <center>
                                            <Image
                                              className="blog-page-image"
                                              src={iv.url}
                                              preview={false}
                                            />
                                          </center>
                                        </Fade>
                                      ) : null}
                                      {iv.type === 'V' ? (
                                        <Fade>
                                          <center>
                                            <YouTube
                                              videoId={iv.code}
                                              opts={{ width: '98%' }}
                                              style={{ width: '98%' }}
                                            />
                                          </center>
                                        </Fade>
                                      ) : null}
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            ))}
                        </Row>
                      ))}
                      <Fade>
                        <Divider />
                      </Fade>
                      {this.state.blogcomment.length > 0 ? (
                        <Card>
                          <List
                            itemLayout="vertical"
                            dataSource={this.state.blogcomment}
                            renderItem={(item, index) => (
                              <Fade>
                                <List.Item
                                  actions={[
                                    <Row key={item} justify={'end'}>
                                      <Col
                                        className={
                                          this.state.screen === true ||
                                          this.state.screen === 'true'
                                            ? 'blog-page-detail-dark'
                                            : 'blog-page-detail-light'
                                        }
                                      >
                                        <IoCalendarOutline
                                          size={16}
                                          style={{ marginRight: 5 }}
                                        />
                                        {item.BLOG_COMMENT_DATE}
                                      </Col>
                                      <Col>
                                        <Divider type="vertical" />
                                      </Col>
                                      <Col
                                        className={
                                          this.state.screen === true ||
                                          this.state.screen === 'true'
                                            ? 'blog-page-detail-dark'
                                            : 'blog-page-detail-light'
                                        }
                                      >
                                        <IoTimeOutline
                                          size={16}
                                          style={{ marginRight: 5 }}
                                        />
                                        {item.BLOG_COMMENT_TIME}
                                      </Col>
                                    </Row>,
                                  ]}
                                >
                                  <List.Item.Meta
                                    title={item.NAME}
                                    description={item.BODY}
                                  />
                                </List.Item>
                              </Fade>
                            )}
                          />
                        </Card>
                      ) : null}
                      <Fade>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'blog-page-title-dark'
                              : 'blog-page-title-light'
                          }
                        >
                          Deixe um comentário
                        </Typography>
                      </Fade>
                      <Fade>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'blog-page-paragraph-dark'
                              : 'blog-page-paragraph-light'
                          }
                          style={{ marginTop: -15, marginBottom: 15 }}
                        >
                          O seu endereço de e-mail não será publicado
                        </Typography>
                      </Fade>
                      <Fade>
                        <Card size="small" hoverable>
                          {this.state._loading ? (
                            <center>
                              <Spin />
                            </center>
                          ) : (
                            <Form layout="vertical" onFinish={this.onSend}>
                              <Form.Item
                                label="Nome Completo"
                                name="NAME"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label="E-mail"
                                name="EMAIL"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                  },
                                  {
                                    type: 'email',
                                    message: 'E-mail inválido!',
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label="Mensagem"
                                name="BODY"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  style={{ resize: 'none' }}
                                  rows={6}
                                />
                              </Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-primary"
                                shape="round"
                              >
                                Enviar
                              </Button>
                            </Form>
                          )}
                        </Card>
                      </Fade>
                    </Col>
                    <Col
                      md={8}
                      xs={24}
                      className="blod-dest-side"
                      style={{ height: '100%' }}
                    >
                      <Row>
                        <Col span={24}>
                          <Typography
                            className={
                              this.state.screen === true ||
                              this.state.screen === 'true'
                                ? 'recent-post-dark'
                                : 'recent-post-light'
                            }
                          >
                            Posts Recentes
                          </Typography>
                        </Col>
                        {this.state.blognew.map((v, i) => (
                          <Col key={i} span={24} style={{ marginBottom: 15 }}>
                            <Link to={'/blog/' + v.GUID} onClick={this.onView}>
                              <CardNoticia
                                screen={this.state.screen}
                                type="side"
                                item={v}
                              />
                            </Link>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Result title="Página não encontrada" />
                )}
              </>
            )}
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default BlogPage
