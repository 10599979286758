import React from 'react'
import { Col, Form, Row, Typography, Pagination, Table, Input } from 'antd'

import './styles.css'

class TableComponent extends React.Component {
  render = () => {
    const {
      _border,
      _expandedRows,
      _columns,
      _data,
      _onChangeTable,
      _onSend,
      _loading,
      _onChangePagination,
      _pagination,
      _onChangeSearch,
      _search,
    } = this.props

    return (
      <Row className="table__row_table" gutter={[0, 8]}>
        {_border ? null : (
          <Col span={24}>
            <Row>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className="table__summary">
                  Total de {_pagination.total} registros retornados
                </Typography>
              </Col>
              <Col span={12}>
                <Form
                  layout="vertical"
                  onFieldsChange={_onSend}
                  className="table__form_filter"
                >
                  <Form.Item name="search">
                    <Input
                      onChange={_onChangeSearch}
                      placeholder={'Pesquisar...'}
                      key="SEARCH"
                      name="SEARCH"
                      id="SEARCH"
                      className="table__form_filter_input"
                      value={_search}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Table
            size="small"
            pagination={false}
            onChange={_onChangeTable}
            columns={_columns}
            dataSource={_data}
            loading={_loading}
            scroll={{ x: true }}
            expandable={_expandedRows}
            className={_border ? `table__ table__border` : `table__`}
            // rowSelection={null}
          />
        </Col>
        {_pagination?.current > 0 ? (
          <Col span={24}>
            <Row justify="end">
              <Pagination
                size="small"
                current={_pagination.current}
                defaultCurrent={_pagination.current}
                total={_pagination.total}
                onChange={_onChangePagination}
                showSizeChanger
              />
            </Row>
          </Col>
        ) : null}
      </Row>
    )
  }
}

export default TableComponent
