import { Button, Col, Image, Row, Typography } from 'antd'
import React, { useCallback, useState } from 'react'

import Particles from 'react-particles'
import { loadSlim } from 'tsparticles-slim'

import img from '../assets/figma/astro-light.png'
import { Link } from 'react-router-dom'

const ParticlesLight = ({ title, desc }) => {
  const [up, setUp] = useState(false)

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {}, [])

  setTimeout(() => {
    setUp(!up)
  }, 5000)

  return (
    <Col span={24} style={{ height: '100vh' }} className="colparticles">
      <Row className="row-reverse">
        <Col span={24} className="site-row-particles">
          <Particles
            id="tsparticles"
            options={{
              background: { color: { value: '#EFEFEF' } },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: { enable: false },
                  onHover: { enable: false },
                  resize: true,
                },
              },
              particles: {
                color: { value: '#0D0D0D' },
                links: {
                  color: '#0D0D0D',
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: 'none',
                  enable: true,
                  outModes: { default: 'bounce' },
                  random: false,
                  speed: 6,
                  straight: false,
                },
                number: { density: { enable: true, area: 800 }, value: 40 },
                opacity: { value: 0.5 },
                shape: { type: 'circle' },
                size: { value: { min: 1, max: 5 } },
              },
              detectRetina: true,
            }}
            init={particlesInit}
            loaded={particlesLoaded}
          />
        </Col>
        <Col span={24} className="particle-content">
          <Row style={{ height: '100vh' }} align={'middle'}>
            <Col md={12} xs={0}>
              <Typography className="header-title-light">{title}</Typography>
              <Typography className="header-text-light">{desc}</Typography>
              <div className="line-buttons">
                <Link to="/contato">
                  <Button className="button-contat-light">Contato</Button>
                </Link>
                <Link to="/quemsomos">
                  <Button className="button-more-light">Saiba mais</Button>
                </Link>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <center>
                <Image
                  src={img}
                  preview={false}
                  className={'astro-light ' + (up ? 'up' : 'down')}
                />
              </center>
            </Col>
            <Col md={0} xs={24}>
              <Typography className="header-title-light">{title}</Typography>
              <Typography className="header-text-light">{desc}</Typography>
              <div className="line-buttons">
                <Link to="/contato">
                  <Button className="button-contat-light">Contato</Button>
                </Link>
                <Link to="/quemsomos">
                  <Button className="button-more-light">Saiba mais</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default ParticlesLight
