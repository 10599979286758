import React from 'react'
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Input,
  Row,
  Spin,
  Upload,
  message,
} from 'antd'
import { Link, Navigate } from 'react-router-dom'

import {
  POST_API,
  POST_CATCH,
  getToken,
  getUPLOADAPI,
} from '../../services/api'
import { PlusOutlined } from '@ant-design/icons'
import {
  IoAdd,
  IoArrowBack,
  IoArrowDown,
  IoArrowForward,
  IoArrowUp,
  IoClose,
} from 'react-icons/io5'

import { MdTitle } from 'react-icons/md'
import { BsCameraVideo, BsImage, BsTextParagraph } from 'react-icons/bs'
import { LiaPhotoVideoSolid } from 'react-icons/lia'

class AdminBlogPage extends React.Component {
  state = {
    _navigate: false,
    _loading: false,
    _screen: true,
    _params: window.location.href.split('/'),
    defaultValue: null,
    rows: [],
    filter: {
      BLOG_CATEGORY_ID: [],
    },
  }

  back = '/admin/publicacoes'

  componentDidMount = () => {
    this.onView()
  }

  onView = async () => {
    POST_API('/blog_page/search.php', {
      token: getToken(),
      type: 'list',
      filter: JSON.stringify({ BLOG_ID: this.state._params[5] }),
      sorter: JSON.stringify({
        columnKey: 'blog_page.`ROW`, blog_page.`COLUMN`, blog_page.`ITEM`',
        order: 'ascend',
      }),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ rows: res.data })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
    POST_API('/blog/search.php', {
      token: getToken(),
      type: 'view',
      filter: JSON.stringify({ ID: this.state._params[5] }),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ defaultValue: res.data })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _screen: false }))
  }

  onSend = () => {
    this.setState({ _loading: true })
    POST_API('/blog_page/save.php', {
      token: getToken(),
      master: JSON.stringify(this.state.rows),
      blog: JSON.stringify(this.state.defaultValue),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _loading: false }))
  }

  newRow = (value) => {
    let array = []
    if (value === 1) array = [{ items: [] }]
    if (value === 2) array = [{ items: [] }, { items: [] }]
    if (value === 3) array = [{ items: [] }, { items: [] }, { items: [] }]
    if (value === 4)
      array = [{ items: [] }, { items: [] }, { items: [] }, { items: [] }]

    this.setState({
      rows: [...this.state.rows, { type: value, columns: array }],
    })
  }

  onAdd = (row, col, type) => {
    const array = this.state.rows
    array[row].columns[col].items.push({ type })
    this.setState({ rows: array })
  }

  onChange = (row, col, item, valor, type = 'T') => {
    const array = this.state.rows

    if (type === 'T')
      array[row].columns[col].items[item].value = valor.target.value
    if (type === 'A') array[row].columns[col].items[item].value = valor.fileList
    if (type === 'A')
      array[row].columns[col].items[item].url =
        valor.fileList?.[0]?.response?.path

    this.setState({ rows: array })
  }

  onUpItem = (row, col, item) => {
    const array = this.state.rows
    array[row].columns[col].items.splice(
      item - 1,
      0,
      array[row].columns[col].items.splice(item, 1)[0],
    )
    this.setState({ rows: array })
  }

  onDeleteItem = (row, col, item) => {
    const array = this.state.rows
    array[row].columns[col].items.splice(item, 1)
    this.setState({ rows: array })
  }

  onDownItem = (row, col, item) => {
    const array = this.state.rows
    array[row].columns[col].items.splice(
      item + 1,
      0,
      array[row].columns[col].items.splice(item, 1)[0],
    )
    this.setState({ rows: array })
  }

  onUpColumn = (row, col) => {
    const array = this.state.rows
    array[row].columns.splice(col - 1, 0, array[row].columns.splice(col, 1)[0])
    this.setState({ rows: array })
  }

  onDeleteColumn = (row, col) => {
    const array = this.state.rows
    array[row].columns.splice(col, 1)
    array[row].type = array[row].type - 1
    this.setState({ rows: array })
  }

  onAddColumn = (row) => {
    const array = this.state.rows
    array[row].type = array[row].type + 1
    array[row].columns.push({ items: [] })
    this.setState({ rows: array })
  }

  onDownColumn = (row, col) => {
    const array = this.state.rows
    array[row].columns.splice(col + 1, 0, array[row].columns.splice(col, 1)[0])
    this.setState({ rows: array })
  }

  onUpRow = (row) => {
    const array = this.state.rows
    array.splice(row - 1, 0, array.splice(row, 1)[0])
    this.setState({ rows: array })
  }

  onDeleteRow = (row) => {
    const array = this.state.rows
    array.splice(row, 1)
    this.setState({ rows: array })
  }

  onDownRow = (row) => {
    const array = this.state.rows
    array.splice(row + 1, 0, array.splice(row, 1)[0])
    this.setState({ rows: array })
  }

  render = () => {
    if (this.state._navigate) {
      return <Navigate to={this.back} />
    }

    return (
      <Row className="page-content">
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={this.back}>
            <Button
              type="primary"
              size="small"
              shape="round"
              className="sys-btn-primary sys-btn-small"
            >
              Voltar
            </Button>
          </Link>
        </Col>
        {this.state._screen ? (
          <Col span={24}>
            <center>
              <Spin />
            </center>
          </Col>
        ) : (
          <Col span={24} style={{ marginTop: 10 }}>
            <Card>
              <Row>
                <Col span={24}>
                  {this.state.rows.map((v, i) => (
                    <>
                      <Row
                        gutter={[16, 16]}
                        key={i}
                        className="page-builder-row-select"
                      >
                        {v.columns.map((cv, ci) => (
                          <Col
                            key={ci}
                            span={24 / v.type}
                            className="page-builder-column"
                          >
                            <Row>
                              {cv.items.map((iv, ii) => (
                                <Col
                                  span={24}
                                  key={ii}
                                  style={{ marginBottom: 10 }}
                                >
                                  {iv.type === 'T' ? (
                                    <Input
                                      placeholder="Título"
                                      value={iv.value}
                                      onChange={(valor) =>
                                        this.onChange(i, ci, ii, valor)
                                      }
                                    />
                                  ) : null}
                                  {iv.type === 'P' ? (
                                    <Input.TextArea
                                      rows={4}
                                      placeholder="Parágrafo"
                                      value={iv.value}
                                      onChange={(valor) =>
                                        this.onChange(i, ci, ii, valor)
                                      }
                                    />
                                  ) : null}
                                  {iv.type === 'I' ? (
                                    <>
                                      {iv.url !== undefined ? (
                                        <Image
                                          className="fullwidth"
                                          src={iv.url}
                                          preview={false}
                                        />
                                      ) : null}
                                      <Upload
                                        accept="image/*"
                                        action={getUPLOADAPI}
                                        listType="picture"
                                        className="upload-list-inline"
                                        maxCount={1}
                                        fileList={iv.value}
                                        onChange={(valor) =>
                                          this.onChange(i, ci, ii, valor, 'A')
                                        }
                                      >
                                        <div>
                                          <Button block type="primary">
                                            Escolher Mídia
                                          </Button>
                                        </div>
                                      </Upload>
                                    </>
                                  ) : null}
                                  {iv.type === 'V' ? (
                                    <Input
                                      placeholder="Link Youtube"
                                      type="link"
                                      value={iv.value}
                                      onChange={(valor) =>
                                        this.onChange(i, ci, ii, valor)
                                      }
                                    />
                                  ) : null}
                                  <Row justify={'center'}>
                                    {ii !== 0 ? (
                                      <Col>
                                        <IoArrowUp
                                          onClick={() =>
                                            this.onUpItem(i, ci, ii)
                                          }
                                          title="Mudar posição"
                                          size={16}
                                          className="close-page-builder"
                                        />
                                      </Col>
                                    ) : null}
                                    <Col>
                                      <IoClose
                                        onClick={() =>
                                          this.onDeleteItem(i, ci, ii)
                                        }
                                        title="Deletar"
                                        size={16}
                                        className="close-page-builder"
                                      />
                                    </Col>
                                    {ii + 1 !== cv.items.length ? (
                                      <Col>
                                        <IoArrowDown
                                          onClick={() =>
                                            this.onDownItem(i, ci, ii)
                                          }
                                          title="Mudar posição"
                                          size={16}
                                          className="close-page-builder"
                                        />
                                      </Col>
                                    ) : null}
                                  </Row>
                                </Col>
                              ))}
                            </Row>
                            <Row>
                              <Col
                                md={6}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 100,
                                }}
                              >
                                <Button
                                  onClick={() => this.onAdd(i, ci, 'T')}
                                  className="addButtonPage"
                                  title="Adicionar Título"
                                  type="primary"
                                  shape="circle"
                                >
                                  <MdTitle size={24} />
                                </Button>
                              </Col>
                              <Col
                                md={6}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 100,
                                }}
                              >
                                <Button
                                  onClick={() => this.onAdd(i, ci, 'P')}
                                  className="addButtonPage"
                                  title="Adicionar Parágrafo"
                                  type="primary"
                                  shape="circle"
                                >
                                  <BsTextParagraph size={24} />
                                </Button>
                              </Col>
                              <Col
                                md={6}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 100,
                                }}
                              >
                                <Button
                                  onClick={() => this.onAdd(i, ci, 'I')}
                                  className="addButtonPage"
                                  title="Adicionar Foto"
                                  type="primary"
                                  shape="circle"
                                >
                                  <BsImage size={24} />
                                </Button>
                              </Col>
                              <Col
                                md={6}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: 100,
                                }}
                              >
                                <Button
                                  onClick={() => this.onAdd(i, ci, 'V')}
                                  className="addButtonPage"
                                  title="Adicionar Vídeo"
                                  type="primary"
                                  shape="circle"
                                >
                                  <BsCameraVideo size={24} />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                        {v.columns.map((cv, ci) => (
                          <Col
                            key={ci}
                            span={24 / v.type}
                            className="page-builder-column-op"
                          >
                            <Row justify={'center'}>
                              {ci !== 0 ? (
                                <Col>
                                  <IoArrowBack
                                    onClick={() => this.onUpColumn(i, ci)}
                                    title="Mudar posição"
                                    size={16}
                                    className="close-page-builder"
                                  />
                                </Col>
                              ) : null}
                              <Col>
                                <IoClose
                                  onClick={() => this.onDeleteColumn(i, ci)}
                                  title="Deletar"
                                  size={16}
                                  className="close-page-builder"
                                />
                              </Col>
                              {ci + 1 !== v.columns.length ? (
                                <Col>
                                  <IoArrowForward
                                    onClick={() => this.onDownColumn(i, ci)}
                                    title="Mudar posição"
                                    size={16}
                                    className="close-page-builder"
                                  />
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                        ))}
                      </Row>
                      <Row
                        justify={'center'}
                        style={{ marginTop: -15, marginBottom: 10 }}
                      >
                        {i !== 0 ? (
                          <Col>
                            <IoArrowUp
                              onClick={() => this.onUpRow(i)}
                              title="Mudar posição"
                              size={16}
                              className="close-page-builder"
                            />
                          </Col>
                        ) : null}
                        {v.type !== 4 ? (
                          <Col>
                            <IoAdd
                              onClick={() => this.onAddColumn(i)}
                              title="Adicionar Coluna"
                              size={16}
                              className="close-page-builder"
                            />
                          </Col>
                        ) : null}
                        <Col>
                          <IoClose
                            onClick={() => this.onDeleteRow(i)}
                            title="Deletar"
                            size={16}
                            className="close-page-builder"
                          />
                        </Col>
                        {i + 1 !== v.length ? (
                          <Col>
                            <IoArrowDown
                              onClick={() => this.onDownRow(i)}
                              title="Mudar posição"
                              size={16}
                              className="close-page-builder"
                            />
                          </Col>
                        ) : null}
                      </Row>
                    </>
                  ))}

                  <Row className="page-builder-row">
                    <span>Adicionar linha</span>
                    <Col
                      style={{ marginTop: 5, marginBottom: 5 }}
                      xs={12}
                      md={4}
                    >
                      <center>
                        <Button
                          onClick={() => this.newRow(1)}
                          className="page-builder-button"
                        >
                          1<br />
                          coluna
                        </Button>
                      </center>
                    </Col>
                    <Col
                      style={{ marginTop: 5, marginBottom: 5 }}
                      xs={12}
                      md={4}
                    >
                      <center>
                        <Button
                          onClick={() => this.newRow(2)}
                          className="page-builder-button"
                        >
                          2<br />
                          coluna
                        </Button>
                      </center>
                    </Col>
                    <Col
                      style={{ marginTop: 5, marginBottom: 5 }}
                      xs={12}
                      md={4}
                    >
                      <center>
                        <Button
                          onClick={() => this.newRow(3)}
                          className="page-builder-button"
                        >
                          3<br />
                          coluna
                        </Button>
                      </center>
                    </Col>
                    <Col
                      style={{ marginTop: 5, marginBottom: 5 }}
                      xs={12}
                      md={4}
                    >
                      <center>
                        <Button
                          onClick={() => this.newRow(4)}
                          className="page-builder-button"
                        >
                          4<br />
                          coluna
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <Button
                    loading={this.state._loading}
                    onClick={this.onSend}
                    type="primary"
                    shape="round"
                    style={{ float: 'right' }}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    )
  }
}

export default AdminBlogPage
