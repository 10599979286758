import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Layout,
  Pagination,
  Row,
  Spin,
  Switch,
  Typography,
  message,
} from 'antd'
import React from 'react'

import logo1 from '../assets/INFINITFY - LOGO - BRANCO.png'
import logo2 from '../assets/INFINITFY - LOGO - PRETO.png'

import fav from '../assets/infinitfy.logo-1-300x300.png'
import sap from '../assets/sap.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { FiMapPin } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import {
  IoLogoInstagram,
  IoLogoYoutube,
  IoMenuOutline,
  IoMoonOutline,
  IoSunnyOutline,
  IoLogoLinkedin,
  IoGlobeOutline,
  IoSendOutline,
  IoMailOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import { Fade } from 'react-reveal'
import CardNoticia from '../components/CardNoticia'

class Blog extends React.Component {
  state = {
    loading: true,
    blog: [],
    blognew: [],
    pagination: {
      current: 1,
      total: 0,
      limit: 6,
    },
    screen: getScreen(),
    select: '',
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    this.loadBlog()
    this.loadBlogNew()
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  loadBlogNew = () => {
    POST_API('/blog/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 2 }),
      sorter: JSON.stringify({
        columnKey: 'DATETIME_INSERT',
        order: 'descend',
      }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ blognew: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  loadBlog = () => {
    this.setState({ loading: true })
    POST_API('/blog/search.php', {
      token: 'X',
      pagination: JSON.stringify(this.state.pagination),
      sorter: JSON.stringify({
        columnKey: 'DATETIME_INSERT',
        order: 'descend',
      }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({
            blog: res.data,
            pagination: {
              ...this.state.pagination,
              total: Number(res.summary.QTDE),
            },
          })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => {
        this.setState({ loading: false })
        console.log(this.state.pagination)
      })
  }

  onChangePagination = (page, size) => {
    window.scrollTo(0, 0)
    this.setState({
      pagination: { ...this.state.pagination, current: page },
    })
    setTimeout(() => {
      this.loadBlog()
    }, 500)
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            <Fade>
              {this.state.loading ? (
                <center>
                  <Spin />
                </center>
              ) : (
                <Row gutter={[16, 16]}>
                  {this.state.blog.map((v, i) => (
                    <Col key={i} xs={24} md={8} style={{ marginBottom: 15 }}>
                      <Fade clear>
                        <Link to={v.GUID}>
                          <CardNoticia item={v} screen={this.state.screen} />
                        </Link>
                      </Fade>
                    </Col>
                  ))}
                  <Col span={24}>
                    <Fade clear>
                      <center>
                        <Pagination
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'pagination-dark'
                              : 'pagination-light'
                          }
                          pageSize={6}
                          current={this.state.pagination.current}
                          total={this.state.pagination.total}
                          onChange={this.onChangePagination}
                        />
                      </center>
                    </Fade>
                  </Col>
                </Row>
              )}
            </Fade>
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default Blog
