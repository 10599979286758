import React from 'react'
import {
  Row,
  Col,
  Card,
  Avatar,
  Upload,
  Button,
  Form,
  Input,
  Divider,
  Spin,
  message,
} from 'antd'

import { IoCameraOutline } from 'react-icons/io5'

import {
  POST_API,
  POST_CATCH,
  getToken,
  getUPLOADAPI,
  setPath,
} from '../services/api'

class AdminPerfil extends React.Component {
  state = {
    _screen: true,
    _loading: false,
    defaultValue: null,
  }

  componentDidMount = () => {
    setPath('credential')
    this.onView()
  }

  onView = async () => {
    this.setState({ _screen: true })
    await POST_API('/credential/search.php', {
      token: getToken(),
      type: 'self',
    })
      .then((rs) => rs.json())
      .then((res) => {
        this.setState({ defaultValue: res.data })
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _screen: false }))
  }

  onSend = (values) => {
    this.setState({ _screen: true })
    POST_API('/credential/self-save.php', {
      token: getToken(),
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.onView()
          message.success({ content: res.msg, key: 'screen' })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => {
        message.error({
          content: 'Não foi possível estabelecer uma conexão com o sistema',
          key: 'screen',
        })
      })
      .finally(() =>
        setTimeout(() => {
          this.setState({ _screen: false })
        }, 500),
      )
  }

  onSendPass = (values) => {
    this.setState({ _screen: true })
    POST_API('/credential/self-pass.php', {
      token: getToken(),
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => {
        message.error({
          content: 'Não foi possível estabelecer uma conexão com o sistema',
          key: 'screen',
        })
      })
      .finally(() => this.setState({ _screen: false }))
  }

  onChangePic = (value) => {
    if (value.file.response?.url) {
      this.setState({ _screen: true })
      POST_API('/credential/self-save.php', {
        token: getToken(),
        master: JSON.stringify({ AVATAR: value.file.response?.url }),
      })
        .then((rs) => rs.json())
        .then((res) => {
          if (res.return) {
            this.onView()
            message.success({ content: res.msg, key: 'screen' })
          } else {
            message.warning({ content: res.msg, key: 'screen' })
          }
        })
        .catch(() => {
          message.error({
            content: 'Não foi possível estabelecer uma conexão com o sistema',
            key: 'screen',
          })
        })
        .finally(() => this.onView())
    }
  }

  render = () => {
    return (
      <Row className="page-content">
        <Col span={24}>
          {this.state._screen ? (
            <center>
              <Spin />
            </center>
          ) : (
            <Row gutter={[18, 18]} className="profile-row">
              <Col md={16} xs={24}>
                <Card bordered title="Dados Usuário" size={'small'}>
                  <Form
                    onFinish={this.onSend}
                    layout="vertical"
                    initialValues={this.state.defaultValue}
                  >
                    <Row gutter={[10, 0]}>
                      <Col md={24} xs={24}>
                        <Form.Item
                          label="Nome de usuário"
                          name="NAME"
                          className="profile-form-item"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={16} xs={24}>
                        <Form.Item
                          label="E-mail"
                          name="LOGIN"
                          className="profile-form-item"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Perfil de usuário"
                          name="TYPE_NAME"
                          className="profile-form-item"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Button
                          htmlType="submit"
                          type="primary"
                          shape="round"
                          style={{ float: 'right' }}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
              <Col md={8} xs={24}>
                <Card bordered>
                  <Row
                    justify="center"
                    align={'middle'}
                    style={{ flexDirection: 'column' }}
                  >
                    <Avatar
                      size={200}
                      shape="circle"
                      src={this.state.defaultValue?.AVATAR}
                      style={{ marginBottom: 12 }}
                    />
                    <Upload
                      maxCount={1}
                      showUploadList={false}
                      accept="image/jpg,image/jpeg,image/png"
                      action={getUPLOADAPI}
                      onChange={this.onChangePic}
                      className={`admin-profile-upload`}
                    >
                      <Button shape="circle" className="btn-nav" type="default">
                        <IoCameraOutline size={20} />
                      </Button>
                    </Upload>
                    <Divider />
                    <Form onFinish={this.onSendPass}>
                      <Form.Item
                        label="Senha atual"
                        name="OLD_PASSWORD"
                        rules={[
                          { required: true, message: 'Campo obrigatório!' },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item
                        label="Nova senha"
                        name="NEW_PASSWORD"
                        rules={[
                          { required: true, message: 'Campo obrigatório!' },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          shape="round"
                          htmlType="submit"
                          className="btn-primary"
                          block
                          type="primary"
                        >
                          Alterar Senha
                        </Button>
                      </Form.Item>
                    </Form>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    )
  }
}

export default AdminPerfil
