import { Avatar, Button, Divider, Image, Tag, Typography } from 'antd'
import React from 'react'

import { Link } from 'react-router-dom'

class CardNoticia extends React.Component {
  render = () => {
    const { item, screen } = this.props

    return (
      <div
        className={`card-noticia ${this.props.type}`}
        style={{ cursor: 'pointer' }}
      >
        <Tag color="var(--color01)" className="blog-tag">
          {item?.BLOG_CATEGORY_NAME}
        </Tag>
        <Image className="blog-image" preview={false} src={item?.AVATAR} />
        <Typography
          className={
            screen === true || screen === 'true'
              ? 'blog-title-dark'
              : 'blog-title-light'
          }
        >
          {item?.TITLE}
        </Typography>
        <Typography
          className={
            screen === true || screen === 'true'
              ? 'blog-text-dark'
              : 'blog-text-light'
          }
        >
          {item?.DESC}
        </Typography>
      </div>
    )
  }
}

export default CardNoticia
