import './App.css'
import React from 'react'
import AppStack from './routes/AppStack'
import { POST_API, POST_CATCH } from './services/api'
import { message } from 'antd'

class App extends React.Component {
  componentDidMount = () => {
    POST_API('/config/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 999 }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          // document.querySelector(':root').style.setProperty('--color01', `${res.data.color01}`);
          // document.querySelector(':root').style.setProperty('--color02', `${res.data.color02}`);
          // document.querySelector(':root').style.setProperty('--color03', `${res.data.color03}`);
          // document.querySelector(':root').style.setProperty('--color03-alt', `${res.data.color03}b2`);
          // document.querySelector(':root').style.setProperty('--color04', `${res.data.color04}`);
          // document.querySelector(':root').style.setProperty('--color04-alt', `${res.data.color04}b2`);
          // document.querySelector(':root').style.setProperty('--color05', `${res.data.color05}`);
          // document.querySelector(':root').style.setProperty('--color06', `${res.data.color06}`);
          // document.querySelector(':root').style.setProperty('--color07', `${res.data.color07}`);
          // document.querySelector(':root').style.setProperty('--color08', `${res.data.color08}3b`);
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
  }

  render = () => {
    return <AppStack />
  }
}

export default App
