import React from 'react'
import { Button, Card, Col, Form, Input, Row, Spin, message } from 'antd'

import { POST_API, POST_CATCH, getToken } from '../services/api'

class AdminConfig extends React.Component {
  state = {
    _navigate: false,
    _loading: false,
    _screen: true,
    _params: window.location.href.split('/'),
    defaultValue: null,
  }

  componentDidMount = () => {
    this.onView()
  }

  onView = async () => {
    POST_API('/config/search.php', { token: getToken(), type: 'view' })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ defaultValue: res.data })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _screen: false }))
  }

  onSend = (values) => {
    this.setState({ _loading: true })
    POST_API('/config/save.php', {
      token: getToken(),
      type: this.props.type,
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
          this.setState({ _navigate: true })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _loading: false }))
  }

  render = () => {
    return (
      <Row className="page-content">
        {this.state._screen ? (
          <Col span={24}>
            <center>
              <Spin />
            </center>
          </Col>
        ) : (
          <Col span={24} style={{ marginTop: 10 }}>
            <Card>
              <Form
                layout="vertical"
                className={`admin-form`}
                onFinish={this.onSend}
                initialValues={this.state.defaultValue}
              >
                <Form.Item hidden name="ID">
                  <Input />
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #01"
                      name="color01"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #02"
                      name="color02"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #03"
                      name="color03"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #04"
                      name="color04"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #05"
                      name="color05"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #06"
                      name="color06"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #07"
                      name="color07"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Cor #08"
                      name="color08"
                      class="profile-form-item"
                    >
                      <Input type="color" />
                    </Form.Item>
                  </Col>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Título Banner"
                      name="TITLE"
                      class="profile-form-item"
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Descrição Banner"
                      name="DESC"
                      class="profile-form-item"
                    >
                      <Input.TextArea rows={5} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      loading={this.state._loading}
                      htmlType="submit"
                      type="primary"
                      shape="round"
                      style={{ float: 'right' }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        )}
      </Row>
    )
  }
}

export default AdminConfig
