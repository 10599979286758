import { Card, Col, Row, Typography } from 'antd'
import React from 'react'

import {
  IoNewspaperOutline,
  IoChatbubbleEllipsesOutline,
  IoMailOpenOutline,
  IoGlobeOutline,
} from 'react-icons/io5'
import { POST_API, POST_CATCH, getToken } from '../services/api'
import {
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

class Dashboard extends React.Component {
  state = {
    data: null,
    graph: null,
  }

  componentDidMount = () => {
    POST_API('/dashboard/kpi.php', { token: getToken() })
      .then((rs) => rs.json())
      .then((res) => {
        this.setState({ data: res.data })
      })
      .catch(() => POST_CATCH())

    POST_API('/dashboard/graph.php', { token: getToken() })
      .then((rs) => rs.json())
      .then((res) => {
        this.setState({ graph: res.data })
      })
      .catch(() => POST_CATCH())
  }

  render = () => {
    return (
      <Row className="page-content" gutter={[8, 8]}>
        <Col xs={12} md={6}>
          <Card hoverable>
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col xs={24} md={8}>
                <center>
                  <div className="dash-kpi globe">
                    <IoGlobeOutline size={40} />
                  </div>
                </center>
              </Col>
              <Col xs={24} md={16}>
                <Typography className="dash-kpi-title">
                  Acesso ao site hoje
                </Typography>
                <Typography className="dash-kpi-value">
                  {this.state?.data?.ACCESS_SITE}
                </Typography>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card hoverable>
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col xs={24} md={8}>
                <center>
                  <div className="dash-kpi newpaper">
                    <IoNewspaperOutline size={40} />
                  </div>
                </center>
              </Col>
              <Col xs={24} md={16}>
                <Typography className="dash-kpi-title">
                  Total postagens
                </Typography>
                <Typography className="dash-kpi-value">
                  {this.state?.data?.BLOG}
                </Typography>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card hoverable>
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col xs={24} md={8}>
                <center>
                  <div className="dash-kpi subscribe">
                    <IoMailOpenOutline size={40} />
                  </div>
                </center>
              </Col>
              <Col xs={24} md={16}>
                <Typography className="dash-kpi-title">
                  Total inscritos
                </Typography>
                <Typography className="dash-kpi-value">
                  {this.state?.data?.SUBSCRIBE}
                </Typography>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card hoverable>
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col xs={24} md={8}>
                <center>
                  <div className="dash-kpi contact">
                    <IoChatbubbleEllipsesOutline size={40} />
                  </div>
                </center>
              </Col>
              <Col xs={24} md={16}>
                <Typography className="dash-kpi-title">
                  aguardando resposta
                </Typography>
                <Typography className="dash-kpi-value">
                  {this.state?.data?.CONTACT}
                </Typography>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={14}>
          <Card
            hoverable
            style={{ height: 350 }}
            title="Acesso mensal"
            size="small"
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                width={500}
                height={300}
                data={this.state.graph}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="Acessos"
                  stroke="var(--color04)"
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Dashboard
