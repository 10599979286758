import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Main from '../pages/Main'
import Contato from '../pages/Contato'
import Blog from '../pages/Blog'
import Login from '../pages/Login'
import Admin from '../pages/Admin'
import AdminUsuarioList from '../pages/Usuario/List'
import AdminUsuarioForm from '../pages/Usuario/Form'
import AdminTimeList from '../pages/Time/List'
import AdminTimeForm from '../pages/Time/Form'
import AdminCaseList from '../pages/Case/List'
import AdminCaseForm from '../pages/Case/Form'
import AdminDepoimentoList from '../pages/Depoimento/List'
import AdminDepoimentoForm from '../pages/Depoimento/Form'
import AdminClienteList from '../pages/Cliente/List'
import AdminClienteForm from '../pages/Cliente/Form'
import AdminConfig from '../pages/Config'
import AdminPerfil from '../pages/Perfil'
import AdminCategoriaList from '../pages/Categoria/List'
import AdminCategoriaForm from '../pages/Categoria/Form'
import AdminBlogList from '../pages/Blog/List'
import AdminBlogForm from '../pages/Blog/Form'
import AdminBlogPage from '../pages/Blog/Page'
import BlogPage from '../pages/BlogPage'
import AdminContatoList from '../pages/Contato/List'
import AdminInscritosList from '../pages/Inscritos/List'
import AdminBlogComment from '../pages/Blog/Comment'
import Dashboard from '../pages/Dashboard'
import Servicos from '../pages/Servicos'
import AdminServicoList from '../pages/Servicos/List'
import AdminServicoForm from '../pages/Servicos/Form'
import Page404 from '../components/Page404'
import ChatSync from '../pages/ChatSync'
import QuemSomos from '../pages/QuemSomos'

class AppStack extends React.Component {
  render = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Main />} />
          <Route path="quemsomos" element={<QuemSomos />} />
          <Route path="servicos" element={<Servicos />} />
          <Route path="chatsync" element={<ChatSync />} />
          <Route path="contato" element={<Contato />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogPage />} />
          <Route path="*" element={<Page404 />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Admin />}>
            <Route path="" element={<Dashboard />} />
            <Route path="config" element={<AdminConfig />} />
            <Route path="perfil" element={<AdminPerfil />} />
            <Route path="usuarios" element={<Outlet />}>
              <Route path="" element={<AdminUsuarioList type="list" />} />
              <Route path="trash" element={<AdminUsuarioList type="trash" />} />
              <Route path="add" element={<AdminUsuarioForm type="add" />} />
              <Route path=":id" element={<AdminUsuarioForm type="edit" />} />
            </Route>
            <Route path="contato" element={<Outlet />}>
              <Route path="" element={<AdminContatoList type="list" />} />
              <Route path="trash" element={<AdminContatoList type="trash" />} />
              {/* <Route path="add" element={<AdminTimeForm type='add' />} />
                            <Route path=":id" element={<AdminTimeForm type='edit' />} /> */}
            </Route>
            <Route path="inscritos" element={<Outlet />}>
              <Route path="" element={<AdminInscritosList type="list" />} />
              <Route
                path="trash"
                element={<AdminInscritosList type="trash" />}
              />
              {/* <Route path="add" element={<AdminTimeForm type='add' />} />
                            <Route path=":id" element={<AdminTimeForm type='edit' />} /> */}
            </Route>
            <Route path="time" element={<Outlet />}>
              <Route path="" element={<AdminTimeList type="list" />} />
              <Route path="trash" element={<AdminTimeList type="trash" />} />
              <Route path="add" element={<AdminTimeForm type="add" />} />
              <Route path=":id" element={<AdminTimeForm type="edit" />} />
            </Route>
            <Route path="servicos" element={<Outlet />}>
              <Route path="" element={<AdminServicoList type="list" />} />
              <Route path="trash" element={<AdminServicoList type="trash" />} />
              <Route path="add" element={<AdminServicoForm type="add" />} />
              <Route path=":id" element={<AdminServicoForm type="edit" />} />
            </Route>
            <Route path="cases" element={<Outlet />}>
              <Route path="" element={<AdminCaseList type="list" />} />
              <Route path="trash" element={<AdminCaseList type="trash" />} />
              <Route path="add" element={<AdminCaseForm type="add" />} />
              <Route path=":id" element={<AdminCaseForm type="edit" />} />
            </Route>
            <Route path="depoimentos" element={<Outlet />}>
              <Route path="" element={<AdminDepoimentoList type="list" />} />
              <Route
                path="trash"
                element={<AdminDepoimentoList type="trash" />}
              />
              <Route path="add" element={<AdminDepoimentoForm type="add" />} />
              <Route path=":id" element={<AdminDepoimentoForm type="edit" />} />
            </Route>
            <Route path="clientes" element={<Outlet />}>
              <Route path="" element={<AdminClienteList type="list" />} />
              <Route path="trash" element={<AdminClienteList type="trash" />} />
              <Route path="add" element={<AdminClienteForm type="add" />} />
              <Route path=":id" element={<AdminClienteForm type="edit" />} />
            </Route>
            <Route path="categorias" element={<Outlet />}>
              <Route path="" element={<AdminCategoriaList type="list" />} />
              <Route
                path="trash"
                element={<AdminCategoriaList type="trash" />}
              />
              <Route path="add" element={<AdminCategoriaForm type="add" />} />
              <Route path=":id" element={<AdminCategoriaForm type="edit" />} />
            </Route>
            <Route path="publicacoes" element={<Outlet />}>
              <Route path="" element={<AdminBlogList type="list" />} />
              <Route path="trash" element={<AdminBlogList type="trash" />} />
              <Route path="add" element={<AdminBlogForm type="add" />} />
              <Route path=":id" element={<AdminBlogForm type="edit" />} />
              <Route path=":id/page" element={<AdminBlogPage />} />
              <Route
                path=":id/comment"
                element={<AdminBlogComment type="list" />}
              />
              <Route
                path=":id/comment/trash"
                element={<AdminBlogComment type="trash" />}
              />
            </Route>
            <Route path="*" element={<></>} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

export default AppStack
