import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Layout,
  Pagination,
  Row,
  Spin,
  Switch,
  Typography,
  message,
} from 'antd'
import React from 'react'

import logo1 from '../assets/INFINITFY - LOGO - BRANCO.png'
import logo2 from '../assets/INFINITFY - LOGO - PRETO.png'

import fav from '../assets/infinitfy.logo-1-300x300.png'
import sap from '../assets/sap.png'

import { FiMapPin } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import {
  IoLogoInstagram,
  IoLogoYoutube,
  IoMenuOutline,
  IoMoonOutline,
  IoSunnyOutline,
  IoLogoLinkedin,
  IoGlobeOutline,
  IoSendOutline,
  IoMailOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  POST_API,
  POST_CATCH,
  getScreen,
  getToken,
  setScreen,
} from '../services/api'
import { Fade } from 'react-reveal'
import Navbar from './Navbar'

class Page404 extends React.Component {
  state = {
    _loading: false,
    screen: getScreen(),
    select: '',
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value })
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
  }

  onSend = (values) => {
    this.setState({ _loading: true })
    POST_API('/contact/save.php', {
      token: getToken(),
      type: 'add',
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _loading: false }))
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            <Fade>
              <Row gutter={[16, 16]}>
                <Col md={24} xs={24}>
                  <Fade clear></Fade>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col
            span={24}
            className={
              'main-footer ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-footer-dark'
                : 'main-footer-light')
            }
          >
            <Row className="main-footer-row" gutter={[8, 56]} align={'top'}>
              <Col span={24}>
                <Divider
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'divider-dark'
                      : 'divider-ligth'
                  }
                  style={{ marginTop: 0, marginBottom: 20 }}
                />
              </Col>
              <Col md={8} xs={24}>
                <Image
                  src={
                    this.state.screen === true || this.state.screen === 'true'
                      ? logo1
                      : logo2
                  }
                  preview={false}
                  className="footer-row-nav-logo"
                />
                <Row gutter={[8, 8]} style={{ marginLeft: 35 }}>
                  <Col>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/infinitfy.company/"
                      rel="noreferrer"
                    >
                      <Button
                        className={
                          this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'social-button-dark'
                            : 'social-button-light'
                        }
                        shape="circle"
                      >
                        <IoLogoInstagram size={18} />
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/infinitfy.company/"
                      rel="noreferrer"
                    >
                      <Button
                        className={
                          this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'social-button-dark'
                            : 'social-button-light'
                        }
                        shape="circle"
                      >
                        <IoGlobeOutline size={18} />
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/infinitfy.company/"
                      rel="noreferrer"
                    >
                      <Button
                        className={
                          this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'social-button-dark'
                            : 'social-button-light'
                        }
                        shape="circle"
                      >
                        <IoLogoLinkedin size={18} />
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      target="_blank"
                      to="https://www.youtube.com/@infinitfy"
                      rel="noreferrer"
                    >
                      <Button
                        className={
                          this.state.screen === true ||
                          this.state.screen === 'true'
                            ? 'social-button-dark'
                            : 'social-button-light'
                        }
                        shape="circle"
                      >
                        <IoLogoYoutube size={18} />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col md={8} xs={24}>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'footer-title-dark'
                      : 'footer-title-light'
                  }
                >
                  Contato
                </Typography>
                <a
                  href="https://www.google.com/maps/place/Condom%C3%ADnio+Edif%C3%ADcio+Rubi%C3%A1cea/@-21.6736856,-49.7534726,15z/data=!4m6!3m5!1s0x94be15ca9250353b:0xe8b76a0fc9b2d934!8m2!3d-21.6736856!4d-49.7534726!16s%2Fg%2F1v9t___z"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'footer-item-dark'
                        : 'footer-item-light'
                    }
                  >
                    <FiMapPin style={{ marginRight: 8 }} /> XV de Novembro, 130
                    - Centro, Lins - SP
                  </Typography>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=5514991120698&text=Estou%20na%20p%C3%A1gina%20da%20Infinity%20e%20tenho%20uma%20d%C3%BAvida!%20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'footer-item-dark'
                        : 'footer-item-light'
                    }
                  >
                    <FaWhatsapp style={{ marginRight: 8 }} /> Whatsapp: +55 (14)
                    99112-0698
                  </Typography>
                </a>
                <a
                  href="mailto:higor.lopes@infinitfy.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    className={
                      this.state.screen === true || this.state.screen === 'true'
                        ? 'footer-item-dark'
                        : 'footer-item-light'
                    }
                  >
                    <IoMailOutline style={{ marginRight: 8 }} /> E-mail:
                    higor.lopes@infinitfy.com
                  </Typography>
                </a>
              </Col>
              <Col md={8} xs={24}>
                <Typography
                  className={
                    this.state.screen === true || this.state.screen === 'true'
                      ? 'footer-title-dark'
                      : 'footer-title-light'
                  }
                >
                  Mantenha-se atualizado
                </Typography>
                <Form onFinish={this.onSend}>
                  <Form.Item
                    name={'EMAIL'}
                    rules={[
                      { required: true, message: 'Preecha seu e-mail' },
                      { type: 'emmail', message: 'E-mail inválido' },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Digite seu e-mail"
                      style={{
                        marginBottom: 10,
                        border: 'none',
                        backgroundColor:
                          this.state.screen === true ||
                          this.state.screen === 'true'
                            ? '#D9D9D9'
                            : '#ffffff',
                      }}
                    />
                    <Button className="button-send" shape="circle" type="text">
                      <IoSendOutline />
                    </Button>
                  </Form.Item>
                </Form>
                <center>
                  <Image width={100} src={sap} preview={false} />
                </center>
              </Col>
              <Col span={24}>
                <Typography
                  className={
                    'center ' +
                    (this.state.screen === true || this.state.screen === 'true'
                      ? 'footer-item-dark'
                      : 'footer-item-light')
                  }
                  style={{ marginTop: 10 }}
                >
                  ©2024 Infinitfy. Todos os direitos reservados.
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Drawer
          placement="right"
          closeIcon={false}
          onClose={this.onMenu}
          open={this.state.menu}
          width={300}
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'modal-dark'
              : 'modal-light'
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Link to={'/'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Home
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'servicos'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Serviços
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'chatsync'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  ChatSync
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'blog'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Blog
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'contato'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Contato
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <center>
                <Switch
                  className="switch-my"
                  defaultChecked={this.state.screen === 'true'}
                  onChange={this.onScreen}
                  unCheckedChildren={<IoSunnyOutline size={18} />}
                  checkedChildren={<IoMoonOutline size={18} />}
                />
              </center>
            </Col>
          </Row>
        </Drawer>
      </Layout.Content>
      /* <Col className="main-main padding-page" style={{marginTop: 80, marginBottom: 80}} span={24}>
                    <Row gutter={[16,16]}>
                        <Col md={12} xs={24}>
                            <Fade clear>

                                <Card size="small" hoverable>
                                    { this.state._loading ? <center><Spin /></center> : (
                                        <Form layout="vertical" onFinish={this.onSend}>
                                            <Form.Item label="Nome Completo" name="NAME" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório!'}, {type: 'email', message: 'E-mail inválido!'}]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Mensagem" name="BODY" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <Input.TextArea style={{resize: "none"}} rows={6} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="btn-primary" shape="round">Enviar</Button>
                                            </Form.Item>
                                        </Form>
                                    ) }
                                </Card>
                            </Fade>
                        </Col>
                        <Col md={12} xs={24}>
                            <Fade clear>
                                <div class="mapouter"><div class="gmap_canvas"><iframe title="iframe" src="https://maps.google.com/maps?q=XV%20de%20Novembro,%20130%20-%20Centro,%20Lins%20-%20SP&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" style={{width: '100%', height: 400}}></iframe><a href="https://blooketjoin.org/blooket-login/">blooket login</a></div></div>
                            </Fade>
                        </Col>
                    </Row>
                </Col>
            </> */
    )
  }
}

export default Page404
