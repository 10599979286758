import {
  Button,
  Col,
  Divider,
  Image,
  Row,
  Form,
  Input,
  Typography,
  message,
  FloatButton,
  Popover,
} from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoLinkedin,
  IoGlobeOutline,
  IoSendOutline,
  IoMailOutline,
  IoLogoWhatsapp,
} from 'react-icons/io5'
import { FiMapPin } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'

import { POST_API, POST_CATCH } from '../services/api'

import logo1 from '../assets/INFINITFY - LOGO - BRANCO.png'
import logo2 from '../assets/INFINITFY - LOGO - PRETO.png'

import sap from '../assets/sap.png'
import { ScrollToTop } from './ScrollToTop'

class Footer extends React.Component {
  onSend = (values) => {
    this.setState({ loading: true })
    POST_API('/subscribe/save.php', {
      token: 'X',
      type: 'add',
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ loading: false }))
  }

  render = () => {
    const { screen } = this.props

    return (
      <Col
        span={24}
        className={
          'main-footer ' +
          (screen === true || screen === 'true'
            ? 'main-footer-dark'
            : 'main-footer-light')
        }
      >
        <Row className="main-footer-row" gutter={[8, 56]} align={'top'}>
          <Col span={24}>
            <Divider
              className={
                screen === true || screen === 'true'
                  ? 'divider-dark'
                  : 'divider-ligth'
              }
              style={{ marginTop: 0, marginBottom: 20 }}
            />
          </Col>
          <Col md={8} xs={24}>
            <Image
              src={screen === true || screen === 'true' ? logo1 : logo2}
              preview={false}
              className="footer-row-nav-logo"
              alt=""
            />
            <Row gutter={[8, 8]} style={{ marginLeft: 35 }}>
              <Col>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/infinitfy.company/"
                  rel="noreferrer"
                >
                  <Button
                    className={
                      screen === true || screen === 'true'
                        ? 'social-button-dark'
                        : 'social-button-light'
                    }
                    shape="circle"
                  >
                    <IoLogoInstagram size={18} />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  to="https://infinitfy.com/"
                  rel="noreferrer"
                >
                  <Button
                    className={
                      screen === true || screen === 'true'
                        ? 'social-button-dark'
                        : 'social-button-light'
                    }
                    shape="circle"
                  >
                    <IoGlobeOutline size={18} />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/infinithub/"
                  rel="noreferrer"
                >
                  <Button
                    className={
                      screen === true || screen === 'true'
                        ? 'social-button-dark'
                        : 'social-button-light'
                    }
                    shape="circle"
                  >
                    <IoLogoLinkedin size={18} />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/@infinitfy"
                  rel="noreferrer"
                >
                  <Button
                    className={
                      screen === true || screen === 'true'
                        ? 'social-button-dark'
                        : 'social-button-light'
                    }
                    shape="circle"
                  >
                    <IoLogoYoutube size={18} />
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={8} xs={24}>
            <Typography
              className={
                screen === true || screen === 'true'
                  ? 'footer-title-dark'
                  : 'footer-title-light'
              }
            >
              Contato
            </Typography>
            <a
              href="https://www.google.com/maps/place/Condom%C3%ADnio+Edif%C3%ADcio+Rubi%C3%A1cea/@-21.6736856,-49.7534726,15z/data=!4m6!3m5!1s0x94be15ca9250353b:0xe8b76a0fc9b2d934!8m2!3d-21.6736856!4d-49.7534726!16s%2Fg%2F1v9t___z"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                className={
                  screen === true || screen === 'true'
                    ? 'footer-item-dark'
                    : 'footer-item-light'
                }
              >
                <FiMapPin style={{ marginRight: 8 }} /> XV de Novembro, 130 -
                Centro, Lins - SP
              </Typography>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5514991120698&text=Estou%20na%20p%C3%A1gina%20da%20Infinity%20e%20tenho%20uma%20d%C3%BAvida!%20"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                className={
                  screen === true || screen === 'true'
                    ? 'footer-item-dark'
                    : 'footer-item-light'
                }
              >
                <FaWhatsapp style={{ marginRight: 8 }} /> Whatsapp: +55 (14)
                99112-0698
              </Typography>
            </a>
            <a
              href="mailto:higor.lopes@infinitfy.com"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                className={
                  screen === true || screen === 'true'
                    ? 'footer-item-dark'
                    : 'footer-item-light'
                }
              >
                <IoMailOutline style={{ marginRight: 8 }} /> E-mail:
                higor.lopes@infinitfy.com
              </Typography>
            </a>
          </Col>
          <Col md={8} xs={24}>
            <Typography
              className={
                screen === true || screen === 'true'
                  ? 'footer-title-dark'
                  : 'footer-title-light'
              }
            >
              Mantenha-se atualizado
            </Typography>
            <Form onFinish={this.onSend}>
              <Form.Item
                name={'EMAIL'}
                rules={[
                  { required: true, message: 'Preecha seu e-mail' },
                  { type: 'emmail', message: 'E-mail inválido' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Digite seu e-mail"
                  style={{
                    marginBottom: 10,
                    border: 'none',
                    backgroundColor:
                      screen === true || screen === 'true'
                        ? '#D9D9D9'
                        : '#ffffff',
                  }}
                />
                <Button className="button-send" shape="circle" type="text">
                  <IoSendOutline />
                </Button>
              </Form.Item>
            </Form>
            <center>
              <Image width={100} src={sap} preview={false} />
            </center>
          </Col>
          <Col span={24}>
            <Typography
              className={
                'center ' +
                (screen === true || screen === 'true'
                  ? 'footer-item-dark'
                  : 'footer-item-light')
              }
              style={{ marginTop: 10 }}
            >
              ©2024 Infinitfy. Todos os direitos reservados.
            </Typography>
          </Col>
        </Row>

        <Popover content="Voltar ao topo" placement="left">
          <ScrollToTop />
        </Popover>

        <Link to={'https://wa.me/5514991120698'} target="_blank">
          <Popover content="Fale conosco!" placement="left">
            <FloatButton
              className="button-whatsapp"
              icon={<IoLogoWhatsapp size={22} color={'#FFF'} />}
            />
          </Popover>
        </Link>
      </Col>
    )
  }
}

export default Footer
