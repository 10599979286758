import {
  Button,
  Carousel,
  Col,
  Divider,
  Image,
  Layout,
  Row,
  Typography,
  message,
} from 'antd'
import React from 'react'

import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import { Fade } from 'react-reveal'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'

class QuemSomos extends React.Component {
  state = {
    screen: getScreen(),
    value: getScreen() === true || getScreen() === 'true' ? 'dark' : 'light',
    select: '',
    parceiros: [],
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    this.loadParceiros()
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value, value: value ? 'dark' : 'light' })
  }

  loadParceiros = () => {
    POST_API('/client/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 999 }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ parceiros: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            <Row gutter={[32, 32]} style={{ marginTop: 20 }} justify={'center'}>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Transformamos negócios por meio de{' '}
                    <span>soluções digitais para SAP</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Na Infinitfy, acreditamos que a inovação é o caminho para
                    transformar negócios.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Ao unir tecnologias, pessoas e as melhores práticas do
                    mercado, liberamos o potencial máximo de empresas usuárias
                    do SAP, especialmente o SAP BTP e S/4Hana. Com isso,
                    trazemos projetos e produtos inovadores à realidade.
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706291541/$rq5v85n35r"
                  />
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      <span>Infinitfy</span> Where innovation knows no bounds
                    </Typography>
                  </center>
                  <center>
                    <Typography className={`who-text-${this.state.value}`}>
                      Para nós, a inovação é mais do que uma palavra –{' '}
                      <b>é uma jornada.</b>
                    </Typography>
                  </center>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Nós transcendemos os padrões de inovação em ambientes SAP
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706291541/$oxknd607tj"
                  />
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    Com nossa cultura de inovação, apoiamos líderes que desejam
                    ampliar operações e oportunidades empresariais, enriquecer a
                    qualidade e a experiência em processos de forma
                    personalizada, e obter vantagem competitiva sustentável.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Soluções proprietárias
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Equipe de experts no mercado SAP
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Primeira empresa certificada SAP Partner Build Edge
                  </Typography>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    O que fazemos?
                  </Typography>
                </Fade>
              </Col>
              <Col span={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    <span>Fábrica de software</span>
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    Desenvolvemos soluções focadas em inovação para atender às
                    necessidades do seu negócio em ambientes SAP.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Desenvolvimento de aplicativos
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Suporte AMS
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Integração e automação
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Inteligência Artificial
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • No-code e low-code
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Innovation Lab
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • E mais
                  </Typography>
                </Fade>
              </Col>
              <Col span={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    <span>Alocação de recursos</span>
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    Oferecemos especialistas qualificados e certificados para
                    suprir as demandas técnicas de projetos e suporte SAP.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Mão de obra especializada SAP
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Consultoria
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Suporte
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • E mais
                  </Typography>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Como fazemos?
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Firmamos parcerias com consultorias SAP e PMEs que não
                    possuem recursos para desenvolver tecnologias e garantir o
                    suporte necessário aos seus clientes.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Capacitamos pessoas internamente para atuarem em projetos
                    especializados. Nossa abordagem garante às empresas maiores
                    margens de lucro e eficiência.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Desenvolvemos soluções inteligentes que melhoram a
                    experiência do usuário e otimizam processos, como o
                    ChatSync.
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Nossos diferenciais
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Foco em pesquisa e desenvolvimento de produtos digitais
                    para SAP BTP.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Desenvolvimento de soluções inovadoras, como o ChatSync.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Análise detalhada de processos e negócios.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Planejamento e desenvolvimento de soluções que automatizam
                    e integram processos.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Compromisso com a inovação e a excelência.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Parceria ideal para empresas buscando liderança no mercado
                    de tecnologia SAP.
                  </Typography>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Empresas que confiam no nosso trabalho
                  </Typography>
                </Fade>
              </Col>
              <Col md={24} xs={0}>
                <Carousel autoplay slidesToShow={4}>
                  {this.state.parceiros.map((v, i) => (
                    <div key={i}>
                      <center>
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: 'contain',
                            marginBottom: 40,
                          }}
                          src={v.AVATAR}
                          preview={false}
                        />
                      </center>
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={0} xs={24}>
                <Carousel autoplay slidesToShow={2}>
                  {this.state.parceiros.map((v, i) => (
                    <div key={i}>
                      <center>
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: 'contain',
                            marginBottom: 40,
                          }}
                          src={v.AVATAR}
                          preview={false}
                        />
                      </center>
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      Alcance o próximo nível do seu SAP
                    </Typography>
                  </center>
                  <center>
                    <Typography
                      className={`who-text-${this.state.value} metricas none`}
                    >
                      Nós guiamos líderes e negócios por um caminho de
                      transformação que gera resultados reais. Descubra como
                      inovar no seu negócio.
                    </Typography>
                  </center>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      Nossa história até aqui
                    </Typography>
                  </center>
                  <div className="metricas none">
                    <Row
                      justify={'space-between'}
                      align={'middle'}
                      style={{ paddingLeft: 50, paddingRight: 50 }}
                    >
                      <Col>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-large'
                              : 'metrica-light metrica-large'
                          }
                        >
                          +{' '}
                          <CountUp
                            start={0}
                            end={60}
                            delay={0}
                            duration={3}
                            enableScrollSpy
                          >
                            {' '}
                            {({ countUpRef, start }) => ({ countUpRef })}{' '}
                          </CountUp>{' '}
                          Projetos
                        </Typography>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-small'
                              : 'metrica-light metrica-small'
                          }
                        >
                          Projetos concluídos
                        </Typography>
                      </Col>
                      <Col>
                        <Divider type="vertical" className="divider-metrica" />
                      </Col>
                      <Col>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-large'
                              : 'metrica-light metrica-large'
                          }
                        >
                          +{' '}
                          <CountUp
                            start={0}
                            end={10}
                            delay={0}
                            duration={3}
                            enableScrollSpy
                          >
                            {' '}
                            {({ countUpRef, start }) => ({ countUpRef })}{' '}
                          </CountUp>{' '}
                          Clientes
                        </Typography>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-small'
                              : 'metrica-light metrica-small'
                          }
                        >
                          Empresas atendidas
                        </Typography>
                      </Col>
                      <Col>
                        <Divider type="vertical" className="divider-metrica" />
                      </Col>
                      <Col>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-large'
                              : 'metrica-light metrica-large'
                          }
                        >
                          +{' '}
                          <CountUp
                            start={0}
                            end={25}
                            delay={0}
                            duration={3}
                            enableScrollSpy
                          >
                            {' '}
                            {({ countUpRef, start }) => ({ countUpRef })}{' '}
                          </CountUp>
                          k Horas
                        </Typography>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-small'
                              : 'metrica-light metrica-small'
                          }
                        >
                          Horas de projeto
                        </Typography>
                      </Col>
                      <Col>
                        <Divider type="vertical" className="divider-metrica" />
                      </Col>
                      <Col>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-large'
                              : 'metrica-light metrica-large'
                          }
                        >
                          +{' '}
                          <CountUp
                            start={0}
                            end={10}
                            delay={0}
                            duration={3}
                            enableScrollSpy
                          >
                            {' '}
                            {({ countUpRef, start }) => ({ countUpRef })}{' '}
                          </CountUp>{' '}
                          Anos
                        </Typography>
                        <Typography
                          className={
                            this.state.screen === true ||
                            this.state.screen === 'true'
                              ? 'metrica-dark metrica-small'
                              : 'metrica-light metrica-small'
                          }
                        >
                          Experiência no mercado
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Quem são nossos gestores
                  </Typography>
                </Fade>
              </Col>
              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706619446/$n3w9qtbvq6r"
                  />
                </Fade>
              </Col>
              <Col md={14} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-subtitle-${this.state.value}`}
                  >
                    <span>Higor Lopes - Fundador e CEO</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    CEO e fundador da Infinitfy, empresa especializada em
                    Inovação, Transformação Digital & Automatização de Sistemas
                    SAP ERP utilizando o SAP BTP.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Higor já trabalhou em grandes empresas, como Burger King e
                    Capgemini, e tem ampla experiência com Blockchain, Machine
                    Learning, Generative AI, Micro-SaaS, Data Science, Cloud
                    Computing, SAP ABAP, SAP ECC, SAP S/4HANA, SAP PI e PO, SAP
                    CI, SAP GRC, entre outros.
                  </Typography>
                </Fade>
              </Col>

              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706619446/$rssnykf38qb"
                  />
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Infinitfy
                  </Typography>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Transformando grandes ideias em soluções de negócios
                  </Typography>
                </Fade>
              </Col>
            </Row>
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default QuemSomos
