import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Image,
  Layout,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd'
import React from 'react'

import logo1 from '../assets/INFINITFY - LOGO - TEXTO BRANCO.png'

import {
  IoMenuOutline,
  IoExitOutline,
  IoSettingsOutline,
  IoGridOutline,
  IoNewspaperOutline,
  IoPeopleOutline,
  IoBriefcaseOutline,
  IoChatboxEllipsesOutline,
  IoColorFilterOutline,
  IoBusinessOutline,
  IoAlbumsOutline,
  IoChatbubbleEllipsesOutline,
  IoMailOpenOutline,
} from 'react-icons/io5'
import { Link, Navigate, Outlet } from 'react-router-dom'
import { POST_API, POST_CATCH, delToken, getToken } from '../services/api'

import { ExclamationCircleOutlined } from '@ant-design/icons'

class Admin extends React.Component {
  state = {
    menu: false,
    navigate: false,
    user: null,
  }

  onMenu = () => {
    this.setState({ menu: !this.state.menu })
  }

  componentDidMount = () => {
    if (getToken()) {
      POST_API('/credential/verify.php', { token: getToken() })
        .then((rs) => rs.json())
        .then((res) => {
          if (!res.return) {
            this.setState({ navigate: true })
          } else {
            this.setState({ user: res.user })
          }
        })
        .catch(() => {
          POST_CATCH()
          this.setState({ navigate: true })
        })
    } else {
      this.setState({ navigate: true })
    }
  }

  onLogOut = () => {
    const self = this
    Modal.confirm({
      title: 'Sair do sistema?',
      icon: <ExclamationCircleOutlined />,
      cancelText: 'Não',
      okText: 'Sim',
      onOk() {
        delToken()
        self.setState({ navigate: true })
      },
      onCancel() {},
    })
  }

  render = () => {
    if (this.state.navigate) return <Navigate to="/login" />

    if (this.state.user !== null) {
      return (
        <Layout.Content className="admin">
          <Row gutter={[8, 8]}>
            <Col md={5} xs={0} className="admin-sidebar">
              <Row className="admin-side-row">
                <Col span={24}>
                  <Image src={logo1} preview={false} />
                </Col>
                <Col span={24}>
                  <Divider
                    style={{
                      backgroundColor: '#FFF',
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Link to={'/admin'}>
                    <div className="btn-sidebar">
                      <IoGridOutline className="icon" size={20} />
                      <Typography className="text">Dashboard</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/publicacoes'}>
                    <div className="btn-sidebar">
                      <IoNewspaperOutline className="icon" size={20} />
                      <Typography className="text">Publicações</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/categorias'}>
                    <div className="btn-sidebar">
                      <IoAlbumsOutline className="icon" size={20} />
                      <Typography className="text">Categorias</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/cases'}>
                    <div className="btn-sidebar">
                      <IoBriefcaseOutline className="icon" size={20} />
                      <Typography className="text">Cases</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/depoimentos'}>
                    <div className="btn-sidebar">
                      <IoChatboxEllipsesOutline className="icon" size={20} />
                      <Typography className="text">Depoimentos</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/time'}>
                    <div className="btn-sidebar">
                      <IoColorFilterOutline className="icon" size={20} />
                      <Typography className="text">Time</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/clientes'}>
                    <div className="btn-sidebar">
                      <IoBusinessOutline className="icon" size={20} />
                      <Typography className="text">Clientes</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/servicos'}>
                    <div className="btn-sidebar">
                      <IoBusinessOutline className="icon" size={20} />
                      <Typography className="text">Serviços</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/usuarios'}>
                    <div className="btn-sidebar">
                      <IoPeopleOutline className="icon" size={20} />
                      <Typography className="text">Usuários</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/contato'}>
                    <div className="btn-sidebar">
                      <IoChatbubbleEllipsesOutline className="icon" size={20} />
                      <Typography className="text">Contato</Typography>
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={'/admin/inscritos'}>
                    <div className="btn-sidebar">
                      <IoMailOpenOutline className="icon" size={20} />
                      <Typography className="text">Inscritos</Typography>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md={19} xs={24} className="admin-main">
              <Row className="admin-navbar" align={'middle'}>
                <Col md={12} xs={20}>
                  <Typography className="admin-name">
                    Olá, <span>{this.state.user.NAME}</span>
                  </Typography>
                </Col>
                <Col md={12} xs={0}>
                  <Row justify={'end'} align={'middle'} gutter={[8, 8]}>
                    <Col>
                      <Link to={'/admin/perfil'}>
                        <Avatar
                          src={this.state.user.AVATAR}
                          style={{ cursor: 'pointer' }}
                          size={26}
                        />
                      </Link>
                    </Col>
                    <Col>
                      <Link to={'/admin/config'}>
                        <Button shape="circle" type="text">
                          <IoSettingsOutline size={20} />
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <Button
                        onClick={this.onLogOut}
                        shape="circle"
                        type="text"
                      >
                        <IoExitOutline size={20} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md={0} xs={4}>
                  <Row justify={'end'} gutter={[8, 8]}>
                    <Col>
                      <Button onClick={this.onMenu} shape="circle" type="text">
                        <IoMenuOutline size={20} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="admin-main-main">
                <Col span={24}>
                  <Outlet />
                </Col>
              </Row>
            </Col>
          </Row>
          <Drawer
            className="admin-sidebar"
            placement="right"
            closeIcon={false}
            onClose={this.onMenu}
            open={this.state.menu}
            width={300}
          >
            <Row className="admin-side-row" gutter={[8, 8]}>
              <Col span={24}>
                <Image src={logo1} preview={false} />
              </Col>
              <Col span={24}>
                <Divider
                  style={{
                    backgroundColor: '#FFF',
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </Col>
              <Col span={24}>
                <Link to={'/admin'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoGridOutline className="icon" size={20} />
                    <Typography className="text">Dashboard</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/publicacoes'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoNewspaperOutline className="icon" size={20} />
                    <Typography className="text">Publicações</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/categorias'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoAlbumsOutline className="icon" size={20} />
                    <Typography className="text">categorias</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/cases'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoBriefcaseOutline className="icon" size={20} />
                    <Typography className="text">Cases</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/depoimentos'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoChatboxEllipsesOutline className="icon" size={20} />
                    <Typography className="text">Depoimentos</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/time'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoColorFilterOutline className="icon" size={20} />
                    <Typography className="text">Time</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/clientes'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoBusinessOutline className="icon" size={20} />
                    <Typography className="text">Clientes</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/usuarios'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoPeopleOutline className="icon" size={20} />
                    <Typography className="text">Usuários</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/contato'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoChatbubbleEllipsesOutline className="icon" size={20} />
                    <Typography className="text">Contato</Typography>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={'/admin/inscritos'}>
                  <div className="btn-sidebar" onClick={this.onMenu}>
                    <IoMailOpenOutline className="icon" size={20} />
                    <Typography className="text">Inscritos</Typography>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              className="sidebar-end"
              align={'middle'}
              gutter={[8, 8]}
            >
              <Col>
                <Link to={'/admin/perfil'}>
                  <Avatar
                    onClick={this.onMenu}
                    src={this.state.user.AVATAR}
                    style={{ cursor: 'pointer' }}
                    size={26}
                  />
                </Link>
              </Col>
              <Col>
                <Link to={'/admin/config'}>
                  <Button onClick={this.onMenu} shape="circle" type="text">
                    <IoSettingsOutline color="#FFF" size={20} />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button onClick={this.onLogOut} shape="circle" type="text">
                  <IoExitOutline color="#FFF" size={20} />
                </Button>
              </Col>
            </Row>
          </Drawer>
        </Layout.Content>
      )
    }

    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Spin />
      </div>
    )
  }
}

export default Admin
