import { Button, Col, Image, Layout, Row, Typography, message } from 'antd'
import React from 'react'

import img1 from '../assets/figma/1.png'
import img2 from '../assets/figma/2.png'
import img3 from '../assets/figma/3.png'

import { POST_API, POST_CATCH, getScreen, setScreen } from '../services/api'
import { Fade } from 'react-reveal'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

class Servicos extends React.Component {
  state = {
    screen: getScreen(),
    value: getScreen() === true || getScreen() === 'true' ? 'dark' : 'light',
    select: '',
    servicos: [],
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const params = window.location.href
    const values = params.split('/')

    this.onSelect(values[3])

    POST_API('/access_site/save.php', { token: 'X', type: 'list' })
      .then((rs) => rs.json())
      .then((res) => {
        if (!res.return) {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())

    this.loadServicos()
  }

  onSelect = (page) => {
    this.setState({ select: page })
  }

  loadServicos = () => {
    POST_API('/service/search.php', {
      token: 'X',
      pagination: JSON.stringify({ current: 1, total: 0, limit: 999 }),
      type: 'list',
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ servicos: res.data })
        } else {
          message.error({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ table_load: false }))
  }

  onScreen = (value) => {
    setScreen(value)
    this.setState({ screen: value, value: value ? 'dark' : 'light' })
  }

  render = () => {
    return (
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row
          id="scroll"
          className={
            this.state.screen === true || this.state.screen === 'true'
              ? 'darkscreen scroll'
              : 'lightscreen scroll'
          }
        >
          <Navbar
            screen={this.state.screen}
            select={this.state.select}
            onMenu={this.onMenu}
            onSelect={this.onSelect}
            onScreen={this.onScreen}
          />
          <Col
            span={24}
            className={
              'main-section ' +
              (this.state.screen === true || this.state.screen === 'true'
                ? 'main-section-dark'
                : 'main-section-light')
            }
            style={{ paddingTop: 100, minHeight: 500 }}
          >
            {/* <Fade>
                            <Row gutter={[0,36]} style={{marginTop: 20}} justify={'center'}>
                                { this.state.servicos.map((v, i) => (
                                    <Col span={24}>
                                        <div className={"services-card-serv " + (this.state.screen === true || this.state.screen === 'true' ? 'services-card-serv-dark' : 'services-card-serv-light' ) }>
                                            <div className="services-card-serv-icon"><Image width={'100%'} preview={false} src={(i === 0 ? img1 : (i === 1 ? img2 : img3) )} /></div>
                                            <div>
                                                <Typography className={this.state.screen === true || this.state.screen === 'true' ? 'services-dark services-large' : 'services-light services-large' }>{v.NAME}</Typography>
                                                <Typography className={this.state.screen === true || this.state.screen === 'true' ? 'services-dark services-text' : 'services-light services-text' }>{v.DESC}</Typography>
                                            </div>
                                        </div>
                                    </Col>
                                )) }
                            </Row>
                        </Fade> */}
            <Row gutter={[32, 64]} style={{ marginTop: 20 }} justify={'center'}>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Fábrica de Software: a inovação que gera{' '}
                    <span>mais produtividade</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Nossa unidade de Fábrica de Software se concentra em
                    oferecer soluções inovadoras e abrangentes para empresas que
                    buscam otimizar suas operações e melhorar a experiência do
                    usuário no ambiente SAP.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706636383/$st4p11obu2"
                  />
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Estamos na vanguarda da inovação tecnológica, oferecendo aos
                    nossos parceiros um novo significado para as palavras:
                    eficiência e eficácia
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Inovação
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Desenvolvimento de tecnologias e aplicações que geram valor
                    e facilitam o trabalho dos usuários.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Integração
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Conexão eficiente para que os dados e informações fluam
                    livremente entre os sistemas.
                  </Typography>
                </Fade>
              </Col>
              <Col md={8} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Automatização
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Recursos técnicos capacitados para melhorar a eficiência,
                    precisão e custos.
                  </Typography>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Uma das primeiras empresas certificadas{' '}
                    <span>SAP Partner Build Edge</span>
                  </Typography>
                </Fade>
              </Col>
              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706637421/$8688tk2fkrb"
                  />
                </Fade>
              </Col>
              <Col md={14} xs={24}>
                <Fade>
                  <Typography
                    className={`who-title-${this.state.value}`}
                    style={{ marginBottom: 20 }}
                  >
                    Nossos diferenciais
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Soluções proprietárias.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Parcerias estratégicas com consultoria SAP e plataformas.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Capacitação intensiva de recursos humanos.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Serviços alinhados às necessidades específicas dos
                    clientes.
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    • Histórico de sucesso com marcas renomadas como T-Systems,
                    Evox, CastGroup, UltraCon e GetBR.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 20 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    <span>Innovation Lab:</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Centro de pesquisa e desenvolvimento de soluções para um
                    futuro mais eficiente
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Com uma abordagem que combina análise detalhada de processos
                    e negócios com o planejamento e desenvolvimento de soluções
                    avançadas, nosso objetivo é criar produtos que automatizam
                    processos e se integram com soluções Cloud e SAP de forma
                    eficaz.
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706637421/$hk64a64sq1"
                  />
                </Fade>
              </Col>
              <Col md={14} xs={24} style={{ marginTop: 100 }}>
                <Fade>
                  <Typography className={`who-text-${this.state.value}`}>
                    Alocação de recursos técnicos SAP:
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Acesso a profissionais especializados com{' '}
                    <span>agilidade e custos otimizados</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Nossa unidade de Alocação de Recursos facilita o acesso a
                    pessoas, tecnologia e processos eficientes para os desafios
                    do dia a dia.
                  </Typography>
                </Fade>
              </Col>
              <Col md={10} xs={24} style={{ marginTop: 100 }}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706712480/$0qjru6t8j39"
                  />
                </Fade>
              </Col>
              <Col md={10} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706712480/$iq536u6ixj"
                  />
                </Fade>
              </Col>
              <Col md={14} xs={24}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Precisa de consultores SAP para impulsionar a transformação
                    digital da sua empresa?
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col md={24} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Capacitamos pessoas internamente para atuarem em projetos
                    especializados.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-title-${this.state.value}`}
                  >
                    Nossa abordagem proporciona às empresas{' '}
                    <span>maiores margens de lucro e eficiência</span>
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    Em um mercado de trabalho cada vez mais competitivo, as
                    empresas precisam contar com as habilidades certas no
                    momento certo para se manterem competitivas.
                  </Typography>
                  <Typography
                    style={{ marginBottom: 20 }}
                    className={`who-text-${this.state.value}`}
                  >
                    A alocação de mão de obra é uma solução que pode ajudar a
                    superar esse desafio.
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Redução de custos
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Acesso à mão de obra especializada e certificada sem custos
                    de contratação e capacitação.
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Agilidade
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Acesso a profissionais qualificados, sem precisar de um
                    processo de recrutamento tradicional.
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Flexibilidade
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Contratação de profissionais SAP por períodos específicos,
                    de acordo com suas necessidades.
                  </Typography>
                </Fade>
              </Col>
              <Col md={6} xs={24} className="chatsync-items">
                <Fade>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Especialização
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Profissionais SAP certificados na área que sua empresa
                    precisa.
                  </Typography>
                </Fade>
              </Col>
              <Col span={24} style={{ marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24}>
                <Fade>
                  <Typography
                    className={`who-title-${this.state.value}`}
                    style={{ marginBottom: 20 }}
                  >
                    Estamos na vanguarda da inovação para proporcionar uma
                    experiência única em SAP
                  </Typography>
                  <Typography className={`who-text-${this.state.value}`}>
                    Conduzimos um processo de seleção minucioso e inovador,
                    integrando as mais recentes tendências para assegurar o
                    melhor nível de qualificação.
                  </Typography>
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Image
                    preview={false}
                    src="https://d335luupugsy2.cloudfront.net/cms/files/752403/1706712480/$un7l5ug19s"
                  />
                </Fade>
              </Col>
              <Col md={12} xs={24}>
                <Fade>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP S/4Hana e SAP ECC (ERP)
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP BTP
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP CPI e SAP PI/PO
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP ABAP e ABAP Cloud
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP Fiori
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP Build
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP HANA
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP CAP (SAP Cloud Application Programming Model)
                  </Typography>
                  <Typography
                    style={{ marginBottom: 10 }}
                    className={`who-text-${this.state.value}`}
                  >
                    • SAP Low-Code & No-Code
                  </Typography>
                  <Link to="/contato">
                    <Button
                      style={{ marginTop: 15 }}
                      className={`btn-primary button-contat-${this.state.value}`}
                      type="primary"
                    >
                      Fale com a gente
                    </Button>
                  </Link>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40, marginBottom: 40 }}>
                <Fade>
                  <center>
                    <Typography className={`who-title-${this.state.value}`}>
                      Alcance o próximo <span>nível do seu SAP</span>
                    </Typography>
                  </center>
                  <center>
                    <Typography
                      style={{ marginTop: 20, marginBottom: 20 }}
                      className={`who-text-${this.state.value}`}
                    >
                      A Alocação de Recursos é uma solução eficaz para as
                      empresas que precisam contar com as habilidades certas no
                      momento certo, sem aumentar seus custos.
                    </Typography>
                  </center>
                  <center>
                    <Link to="/contato">
                      <Button
                        style={{ marginTop: 15 }}
                        className={`btn-primary button-contat-${this.state.value}`}
                        type="primary"
                      >
                        Fale com a gente
                      </Button>
                    </Link>
                  </center>
                </Fade>
              </Col>
              <Col span={24} style={{ marginTop: 40 }}>
                <Fade>
                  <Typography className={`who-title-${this.state.value}`}>
                    Infinitfy
                  </Typography>
                  <Typography className={`who-subtitle-${this.state.value}`}>
                    Transformando grandes ideias em soluções de negócios
                  </Typography>
                </Fade>
              </Col>
            </Row>
          </Col>
          <Footer screen={this.state.screen} />
        </Row>
      </Layout.Content>
    )
  }
}

export default Servicos
