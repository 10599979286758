import { Button, Col, Drawer, Image, Row, Switch } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { IoMenuOutline, IoMoonOutline, IoSunnyOutline } from 'react-icons/io5'
import { GoArrowUpRight } from 'react-icons/go'

import fav from '../assets/infinitfy.logo-1-300x300.png'

class Navbar extends React.Component {
  state = { menu: false }

  onMenu = (boolean = false) => {
    if (boolean === true) window.scrollTo(0, 0)
    this.setState({ menu: !this.state.menu })
  }

  render = () => {
    const { screen, select, onSelect, onScreen } = this.props

    return (
      <>
        <Col
          id="main-nav"
          className={
            screen === true || screen === 'true'
              ? 'dark main-nav'
              : 'light main-nav'
          }
          span={24}
        >
          <Row className="main-row-nav" align={'middle'}>
            <Col xs={0} md={8} sm={24}>
              <Link to="/">
                <Image
                  src={fav}
                  className="main-row-nav-logo"
                  preview={false}
                  alt=""
                />
              </Link>
            </Col>
            <Col xs={12} md={0}>
              <Link to="/">
                <Image
                  src={fav}
                  className="main-row-nav-logo"
                  preview={false}
                  alt=""
                />
              </Link>
            </Col>
            <Col xs={0} md={16}>
              <Row justify={'end'} align={'middle'} gutter={[4, 4]}>
                <Col>
                  <Link to={'/'}>
                    <Button
                      type="text"
                      onClick={() => {
                        window.scrollTo(0, 0)
                        onSelect('')
                      }}
                      className={
                        (screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light') +
                        (select === '' ? ' menu-active' : '')
                      }
                    >
                      Home
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to={'/quemsomos'}>
                    <Button
                      type="text"
                      onClick={() => window.scrollTo(0, 0)}
                      className={
                        (screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light') +
                        (select === 'quemsomos' ? ' menu-active' : '')
                      }
                    >
                      Quem Somos
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to={'/servicos'}>
                    <Button
                      type="text"
                      onClick={() => window.scrollTo(0, 0)}
                      className={
                        (screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light') +
                        (select === 'servicos' ? ' menu-active' : '')
                      }
                    >
                      Serviços
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to={'/chatsync'}>
                    <Button
                      type="text"
                      onClick={() => {
                        window.scrollTo(0, 1500)
                        onSelect('chatsync')
                      }}
                      className={
                        (screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light') +
                        (select === 'chatsync' ? ' menu-active' : '')
                      }
                    >
                      ChatSync
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to={'/blog'}>
                    <Button
                      type="text"
                      onClick={() => window.scrollTo(0, 0)}
                      className={
                        (screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light') +
                        (select === 'blog' ? ' menu-active' : '')
                      }
                    >
                      Blog
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link
                    to={'https://chat.whatsapp.com/DGL7tfj60j25i88o3HAzpq'}
                    target="_blank"
                  >
                    <Button
                      type="text"
                      onClick={() => window.scrollTo(0, 0)}
                      className={
                        screen === true || screen === 'true'
                          ? 'main-row-nav-button nav-button-dark'
                          : 'main-row-nav-button nav-button-light'
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        fontWeight: 'bold',
                      }}
                    >
                      Comunidade
                      <GoArrowUpRight size={20} />
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to={'/contato'}>
                    <Button
                      type="text"
                      onClick={() => window.scrollTo(0, 0)}
                      className={
                        'main-row-nav-button-contat ' +
                        (screen === true || screen === 'true'
                          ? 'nav-button-contat-dark'
                          : 'nav-button-contat-light')
                      }
                    >
                      SAP Store
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Switch
                    className="switch-my"
                    defaultChecked={screen === 'true'}
                    onChange={onScreen}
                    unCheckedChildren={<IoSunnyOutline size={18} />}
                    checkedChildren={<IoMoonOutline size={18} />}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={0}>
              <Row justify={'end'}>
                <Col>
                  <Button
                    onClick={this.onMenu}
                    className={
                      screen === true || screen === 'true'
                        ? 'menu-button-dark'
                        : 'menu-button-light'
                    }
                    type="text"
                    shape="circle"
                  >
                    <IoMenuOutline size={36} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Drawer
          placement="right"
          closeIcon={false}
          onClose={this.onMenu}
          open={this.state.menu}
          width={300}
          className={
            screen === true || screen === 'true' ? 'modal-dark' : 'modal-light'
          }
          style={{ tabSize: -1 }}
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Link to={'/'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Home
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'/quemsomos'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Quem Somos
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'/servicos'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Serviços
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'/chatsync'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  ChatSync
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'/blog'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Blog
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link to={'/contato'}>
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                >
                  Contato
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <Link
                to={'https://chat.whatsapp.com/DGL7tfj60j25i88o3HAzpq'}
                target="_blank"
              >
                <Button
                  onClick={() => this.onMenu(true)}
                  block
                  type="text"
                  shape="round"
                  className="main-row-nav-button mobile"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    fontWeight: 'bold',
                  }}
                >
                  Comunidade
                  <GoArrowUpRight size={20} />
                </Button>
              </Link>
            </Col>
            <Col span={24}>
              <center>
                <Switch
                  className="switch-my"
                  defaultChecked={screen === 'true'}
                  onChange={onScreen}
                  unCheckedChildren={<IoSunnyOutline size={18} />}
                  checkedChildren={<IoMoonOutline size={18} />}
                />
              </center>
            </Col>
          </Row>
        </Drawer>
      </>
    )
  }
}

export default Navbar
