import React from 'react'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  message,
} from 'antd'
import { Link, Navigate } from 'react-router-dom'

import {
  POST_API,
  POST_CATCH,
  getToken,
  getUPLOADAPI,
} from '../../services/api'
import { PlusOutlined } from '@ant-design/icons'

class AdminCaseForm extends React.Component {
  state = {
    _navigate: false,
    _loading: false,
    _screen: true,
    _params: window.location.href.split('/'),
    defaultValue: null,
    filter: {
      TYPE_CREDENTIAL_ID: [],
    },
  }

  back = '/admin/cases'

  componentDidMount = () => {
    if (this.props.type === 'add') {
      this.setState({ _screen: false })
    } else if (this.props.type === 'edit') {
      this.onView()
    }
  }

  onView = async () => {
    POST_API('/case/search.php', {
      token: getToken(),
      type: 'view',
      filter: JSON.stringify({ ID: this.state._params[5] }),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          this.setState({ defaultValue: res.data })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _screen: false }))
  }

  onSend = (values) => {
    this.setState({ _loading: true })
    POST_API('/case/save.php', {
      token: getToken(),
      type: this.props.type,
      master: JSON.stringify(values),
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          message.success({ content: res.msg, key: 'screen' })
          this.setState({ _navigate: true })
        } else {
          message.warning({ content: res.msg, key: 'screen' })
        }
      })
      .catch(() => POST_CATCH())
      .finally(() => this.setState({ _loading: false }))
  }

  render = () => {
    if (this.state._navigate) {
      return <Navigate to={this.back} />
    }

    return (
      <Row className="page-content">
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={this.back}>
            <Button
              type="primary"
              size="small"
              shape="round"
              className="sys-btn-primary sys-btn-small"
            >
              Voltar
            </Button>
          </Link>
        </Col>
        {this.state._screen ? (
          <Col span={24}>
            <center>
              <Spin />
            </center>
          </Col>
        ) : (
          <Col span={24} style={{ marginTop: 10 }}>
            <Card>
              <Form
                layout="vertical"
                className={`admin-form`}
                onFinish={this.onSend}
                initialValues={this.state.defaultValue}
              >
                <Form.Item hidden name="ID">
                  <Input />
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Nome"
                      name="NAME"
                      class="profile-form-item"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Descrição"
                      name="BODY"
                      class="profile-form-item"
                    >
                      <Input.TextArea showCount maxLength={500} rows={4} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item label="Foto Atual" class="profile-form-item">
                      <center>
                        <Avatar
                          shape="square"
                          size={100}
                          src={this.state.defaultValue?.AVATAR}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col md={20} xs={24}>
                    <Form.Item
                      label="Foto Nova"
                      name="AVATAR"
                      class="profile-form-item"
                    >
                      <Upload
                        accept="image/*"
                        action={getUPLOADAPI}
                        listType="picture-card"
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      loading={this.state._loading}
                      htmlType="submit"
                      type="primary"
                      shape="round"
                      style={{ float: 'right' }}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        )}
      </Row>
    )
  }
}

export default AdminCaseForm
