import { Col, Row } from 'antd'
import React, { useCallback } from 'react'

import Particles from 'react-particles'
import { loadSlim } from 'tsparticles-slim'

const ParticlesLogin = ({ title, desc }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {}, [])

  return (
    <Col span={24} style={{ paddingTop: 100 }}>
      <Row>
        <Col span={24} className="site-row-particles">
          <Particles
            id="tsparticles"
            options={{
              background: { color: { value: '#F03612' } },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: { enable: false },
                  onHover: { enable: false },
                  resize: true,
                },
              },
              particles: {
                color: { value: '#ffffff' },
                links: {
                  color: '#ffffff',
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: 'none',
                  enable: true,
                  outModes: { default: 'bounce' },
                  random: false,
                  speed: 6,
                  straight: false,
                },
                number: { density: { enable: true, area: 800 }, value: 80 },
                opacity: { value: 0.5 },
                shape: { type: 'circle' },
                size: { value: { min: 1, max: 5 } },
              },
              detectRetina: true,
            }}
            init={particlesInit}
            loaded={particlesLoaded}
          />
        </Col>
      </Row>
    </Col>
  )
}

export default ParticlesLogin
